import React, { useState } from "react";
import { Card, Form, Button, Row, Col, Alert } from "react-bootstrap";
import "reactjs-popup/dist/index.css";
import axios from "axios";

const Integrations = () => {
  const [apiUrl, setApiUrl] = useState("");
  const [status, setStatus] = useState("Awaiting...");
  const [jsonData, setJsonData] = useState(null);
  const [csvData, setCsvData] = useState("");
  const [selectedSource, setSelectedSource] = useState("");

  const handleApiUrlChange = (e) => {
    setApiUrl(e.target.value);
  };

  const handleSourceChange = (e) => {
    setSelectedSource(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!selectedSource || !apiUrl) {
      setStatus("Please select a data source and enter the API URL.");
      return;
    }

    setStatus("Fetching data...");

    try {
      // Make API request to get JSON data
      const response = await axios.get(apiUrl);
      const jsonData = response.data;

      // Set JSON and CSV data
      setJsonData(jsonData);
      saveToJSONFile(jsonData);
      const csvData = convertToCSV(jsonData);
      setCsvData(csvData);
      saveToCSVFile(csvData);

      setStatus("Data fetched successfully!");
    } catch (error) {
      const jsonData = null;
      setStatus("Error fetching data. Please check the API URL.");
      console.error("Error fetching data:", error);
    }
  };

  const convertToCSV = (jsonData) => {
    // Convert JSON array to CSV string
    const header = Object.keys(jsonData[0]).join(",");
    const rows = jsonData.map((item) => Object.values(item).join(","));
    return `${header}\n${rows.join("\n")}`;
  };

  const saveToJSONFile = (jsonData) => {
    if (!jsonData) {
      setStatus("No data to save. Please fetch data first.");
      return;
    }

    const jsonBlob = new Blob([jsonData], { type: "application/json" });
    const jsonLink = document.createElement("a");
    jsonLink.href = URL.createObjectURL(jsonBlob);
    jsonLink.download = "data.json";
    document.body.appendChild(jsonLink);
    jsonLink.click();
    document.body.removeChild(jsonLink);
  };

  const saveToCSVFile = (csvData) => {
    if (!csvData) {
      setStatus("No data to save. Please fetch data first.");
      return;
    }

    const csvBlob = new Blob([csvData], { type: "text/csv" });
    const csvLink = document.createElement("a");
    csvLink.href = URL.createObjectURL(csvBlob);
    csvLink.download = "data.csv";
    document.body.appendChild(csvLink);
    csvLink.click();
    document.body.removeChild(csvLink);
  };

  return (
    <Card className="shadow mb-4">
      <Card.Header className="d-flex flex-row align-items-center justify-content-between">
        <h6>Data Source Integration</h6>
      </Card.Header>
      <Card.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>Data Source:</b>
                </Form.Label>
                <Form.Select
                  value={selectedSource}
                  onChange={handleSourceChange}
                >
                  <option value="">Select a data source...</option>
                  <option value="ASDSP">
                    Agriculture Sector Development Support Programme (ASDSP)
                  </option>
                  <option value="KALRO">
                    Kenya Agricultural and Livestock Research Organization
                    (KALRO)
                  </option>
                  <option value="KIAMIS">
                    Kenya Integrated Agriculture Management Information System
                    (KIAMIS)
                  </option>
                  <option value="KAMIS">
                    Kenya Agriculture Market Information System (KAMIS)
                  </option>
                  <option value="KAOP">
                    Kenya Agricultural Advisory Platform (KAOP)
                  </option>
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>
                  <b>API URL:</b>
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  value={apiUrl}
                  onChange={handleApiUrlChange}
                  placeholder="Enter API URL here..."
                />
              </Form.Group>
              <Button variant="success" type="submit">
                Consume the API
              </Button>
            </Col>
            <Col md={6}>
              <Card>
                <Card.Body>
                  <Card.Title>Sample of the fetched JSON Data:</Card.Title>
                  <pre>
                    {JSON.stringify(jsonData && jsonData.slice(0, 2), null, 2)}
                  </pre>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Form>
        <div className="mt-3">
          {status && <Alert variant="info">{status}</Alert>}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Integrations;
