import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip, Form, Spinner } from "react-bootstrap";

const DataTopicTabs = ({
  selectedSubject,
  topics,
  fetchedTopics,
  selectedTopics,
  onTopicChange,
  isLoadingTopics,
}) => {
  const [allSelected, setAllSelected] = useState(false);
  useEffect(() => {
    const allSelected = topics.every((element) =>
      selectedTopics.includes(element)
    );
    setAllSelected(allSelected);
  }, [selectedTopics, topics]); // Dependencies array

  const handleSelectAllChange = (e) => {
    if (allSelected) {
      // All are selected, deselect all
      onTopicChange(null, null, null, []);
    } else {
      onTopicChange(null, null, null, topics);
    }
  };

  const tooltip = (
    <Tooltip id="tooltip">
      This is the lower level grouping below subject to describe the topic in a
      subject e.g., in the Actor Registry there will be information about
      farmers, livestock farmers, crop farmers, fisherfolk etc.
    </Tooltip>
  );

  return (
    selectedSubject && (
      <div>
        <OverlayTrigger placement="top" overlay={tooltip}>
          <h6>
            Select Data Topic(s): (<small>Required</small>)
          </h6>
        </OverlayTrigger>

        {isLoadingTopics ? (
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        ) : (
          <>
            {!topics || topics.length === 0 ? (
              <p className="text-muted">
                <em>No topics available for the selected subject.</em>
              </p>
            ) : (
              <>
                <Form.Check
                  type="checkbox"
                  id="checkbox-select-all"
                  label={
                    <span style={{ color: "#347925", fontWeight: "bold" }}>
                      Select All
                    </span>
                  }
                  checked={allSelected}
                  onChange={handleSelectAllChange}
                />
                {topics.map((topic, index) => (
                  <Form.Check
                    type="checkbox"
                    id={`checkbox-topic-${index}`}
                    label={topic}
                    key={index}
                    checked={selectedTopics.includes(topic)}
                    onChange={(e) =>
                      onTopicChange(selectedSubject, topic, e.target.checked)
                    }
                  />
                ))}
              </>
            )}
          </>
        )}
      </div>
    )
  );
};

export default DataTopicTabs;
