import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-light py-3 mt-auto">
      {" "}
      {/* Customize classes as needed */}
      <Container>
        <Row>
          <Col className="text-center">
            <p className="m-0">Copyright &copy; ASDG {currentYear}</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
