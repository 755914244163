import React from "react";
import { Nav } from "react-bootstrap";

const NavUnverified = () => {
  return (
    <Nav
      className="navbar-nav bg-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    ></Nav>
  );
};

export default NavUnverified;
