import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faPhoneAlt,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import Copyright from "../Copyright";

const HomeFooter = () => {
  return (
    <div
      className="container-fluid bg-green text-white-50 footer pt-5 mt-5 wow fadeIn"
      data-wow-delay="0.1s"
    >
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Get In Touch</h5>
            <p className="mb-2">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="me-3" />
              Ministry of Agriculture and Livestock Development, Cathedral Road
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faPhoneAlt} className="me-3" />
              P.O. Box 30028-00100 Kenya
            </p>
            <p className="mb-2">
              <FontAwesomeIcon icon={faEnvelope} className="me-3" />
              info@ASDG
            </p>
            <div className="d-flex pt-2"></div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h5 className="text-white mb-4">Quick Links</h5>
            <a className="btn btn-link text-white-50" href="/about">
              About Us
            </a>
            <a className="btn btn-link text-white-50" href="/contact">
              Contact Us
            </a>
            <a
              className="btn btn-link text-white-50"
              href="/terms-and-conditions"
              target="_blank"
            >
              Terms & Conditions
            </a>
          </div>
        </div>
        <Copyright />
      </div>
    </div>
  );
};

export default HomeFooter;
