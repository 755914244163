import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

import HomeNav from "../../components/Home/HomeNavMenu";

import FaqQuestionItem from "../../components/Home/FaqQuestionItem";
import HomeFooter from "../../components/Home/HomeFooter";

const Faq = () => {
  return (
    <>
      <>
        <div className="container-xxl bg-white p-0">
          {/* Navbar Start */}

          <HomeNav />

          {/* Navbar Ends */}

          {/* Footer Starts */}

          <div className="container-xxl bg-white p-0">
            <div className="container-fluid" style={{ marginTop: "40px" }}>
              <div className="container">
                <h2 className="text-center asdg-title">
                  Frequently Asked Questions (FAQ)
                </h2>
              </div>

              <div className="container mt-5">
                <Accordion defaultActiveKey="0">
                  <FaqQuestionItem
                    questionNumber={1}
                    question="What is ASDG?"
                    answer="The Agriculture Sector Data Gateway (ASDG) is a platform developed by the Ministry of Agriculture and Livestock Development for seamless data exchange amongst actors within the agriculture sector."
                  />

                  <FaqQuestionItem
                    questionNumber={2}
                    question="How can I access information on Agriculture Sector Data Gateway (ASDG)?"
                    answer="To access data on Agriculture Sector Data Gateway (ASDG), simply visit the portal and navigate through the various sections or use the search function to find specific data or resources related to agriculture in Kenya."
                  />

                  <FaqQuestionItem
                    questionNumber={3}
                    question="What type of data and information is available on
                    Agriculture Sector Data Gateway (ASDG)?"
                    answer="Agriculture Sector Data Gateway offers a wide range of agricultural data, including crop production statistics, market trends, weather forecasts, agricultural policies, research findings, and information on farming techniques."
                  />

                  <FaqQuestionItem
                    questionNumber={4}
                    question="Is the data access on Agriculture Sector Data Gateway (ASDG) free to access?"
                    answer="Yes, the majority of the data available on Agriculture Sector Data Gateway (ASDG) is free to access for all stakeholders subject to signing of the Data Sharing Agreement."
                  />

                  <FaqQuestionItem
                    questionNumber={5}
                    question="How frequently is the data updated on Agriculture Sector Data Gateway (ASDG)?"
                    answer="The portal strives to maintain up-to-date data. The frequency of updates may vary based on data sources, availability and variation."
                  />

                  <FaqQuestionItem
                    questionNumber={6}
                    question=" Who can access data on the ASDG Portal?"
                    answer="Organizations that have valid data sharing agreements (DSA) for data exchange approved by the Ministry of Agriculture and Livestock Development. For more information, please reach out to the support team through the provided contact information or use the feedback form available on the ASDG portal."
                  />

                  <FaqQuestionItem
                    questionNumber={7}
                    question="Are there tutorials or guides available for
                    navigating Agriculture Sector Data Gateway (ASDG)?"
                    answer="Yes, the platform offers FAQs to assist users in navigating and utilizing the features of ASDG effectively. These resources are usually accessible on the ASDG Portal in the Resources Tab."
                  />

                  <FaqQuestionItem
                    questionNumber={8}
                    question="Can I download data from Agriculture Sector Data Gateway (ASDG) for offline
                    use?"
                    answer=" Yes, certain datasets and resources available on
                    Agriculture Sector Data Gateway (ASDG) may be downloadable for offline use. Users
                    are encouraged to check the specific data access policies
                    provided alongside the resources."
                  />

                  <FaqQuestionItem
                    questionNumber={9}
                    question="Is Agriculture Sector Data Gateway (ASDG) mobile-friendly?"
                    answer="Yes, ASDG is
                    accessible and user-friendly across various devices,
                    including mobile phones, tablets, and desktops."
                  />

                  {/* Add more FAQQuestion components for other questions */}
                </Accordion>
              </div>
            </div>

            <HomeFooter />
          </div>

          {/* Footer Ends */}
        </div>
      </>
    </>
  );
};

export default Faq;
