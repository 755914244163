import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useLocation } from "react-router-dom";
import html2pdf from "html2pdf.js";

const CodeBlock = ({ value }) => {
  const handleCopy = () => {
    copy(value);
    toast.success("Code copied to clipboard");
  };

  return (
    <div>
      <SyntaxHighlighter language="json" style={vscDarkPlus}>
        {value}
      </SyntaxHighlighter>
      <Button variant="outline-primary" size="sm" onClick={handleCopy}>
        Copy
      </Button>
    </div>
  );
};

const MarkdownView = () => {
  const [markdownContent, setMarkdownContent] = useState("");
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const contentRef = useRef(null);

  const downloadPdf = () => {
    if (contentRef.current && location.pathname === "/a/super-admin-guide") {
      const options = {
        margin: 1,
        filename: "downloaded-content.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      };

      html2pdf().from(contentRef.current).set(options).save();
    }
  };

  const getMarkdownFilePath = (pathname) => {
    switch (pathname) {
      case "/u/user-guide":
        return "/assets/md/user-journey.md";
      case "/a/admin-guide":
        return "/assets/md/admin-journey.md";
      case "/a/super-admin-guide":
        return "/assets/md/super-admin-journey.md";
      default:
        return "/assets/md/error.md";
    }
  };

  useEffect(() => {
    const markdownFilePath = getMarkdownFilePath(location.pathname);

    const loadMarkdownContent = async (filePath) => {
      setLoading(true);
      try {
        const response = await fetch(filePath);
        if (!response.ok) {
          throw new Error("Failed to fetch markdown content");
        }
        const text = await response.text();
        setMarkdownContent(text);
      } catch (error) {
        console.error("Error loading markdown content:", error);
      } finally {
        setLoading(false);
      }
    };

    loadMarkdownContent(markdownFilePath);
  }, [location.pathname]); // Re-run when path changes

  useEffect(() => {
    // Handle smooth scrolling for anchor links
    const handleAnchorClick = (event) => {
      if (event.target.tagName === "A" && event.target.hash.startsWith("#")) {
        event.preventDefault();
        const id = event.target.hash.replace("#", "");
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    document.addEventListener("click", handleAnchorClick);
    return () => document.removeEventListener("click", handleAnchorClick);
  }, []);

  const renderers = {
    a: ({ href, children }) => {
      // Modify links starting with 'assets/' to start with '/assets/md/assets/'
      if (href.startsWith("assets/")) {
        href = `/assets/md/${href}`;
      }

      // Handle internal navigation for SPA links
      if (href.startsWith("#")) {
        return <a href={href}>{children}</a>;
      }
      if (href.startsWith("/")) {
        return <Link to={href}>{children}</Link>;
      }

      // External link, open in a new tab
      return (
        <a href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
    code({ node, inline, className, children, ...props }) {
      const match = /language-(\w+)/.exec(className || "");
      return !inline && match ? (
        <CodeBlock value={String(children).replace(/\n$/, "")} {...props} />
      ) : (
        <code className={className} {...props}>
          {children}
        </code>
      );
    },
    h1: ({ children }) => {
      // Creating a slug/id based on the h2 content
      const slug = children
        .toString()
        .toLowerCase()
        .replace(/\W+/g, "-")
        .replace(/^-*|-*$/g, "");
      return <h1 id={slug}>{children}</h1>;
    },
    h2: ({ children }) => {
      // Creating a slug/id based on the h2 content
      const slug = children
        .toString()
        .toLowerCase()
        .replace(/\W+/g, "-")
        .replace(/^-*|-*$/g, "");
      return <h2 id={slug}>{children}</h2>;
    },
    h3: ({ children }) => {
      // Creating a slug/id based on the h2 content
      const slug = children
        .toString()
        .toLowerCase()
        .replace(/\W+/g, "-")
        .replace(/^-*|-*$/g, "");
      return <h3 id={slug}>{children}</h3>;
    },
    h4: ({ children }) => {
      // Creating a slug/id based on the h2 content
      const slug = children
        .toString()
        .toLowerCase()
        .replace(/\W+/g, "-")
        .replace(/^-*|-*$/g, "");
      return <h4 id={slug}>{children}</h4>;
    },
    img: ({ src, alt }) => {
      // Check if the image src starts with 'assets/' and prepend '/assets/md/' to it
      const newSrc = src.startsWith("assets/") ? `/assets/md/${src}` : src;
      return <img src={newSrc} alt={alt} style={{ maxWidth: "100%" }} />; // Ensure images are responsive
    },
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Card className="pa-2">
      <Card.Body>
        {location.pathname === "/a/super-admin-guide" && (
          <Button onClick={downloadPdf} variant="primary" className="mb-2">
            Download as PDF
          </Button>
        )}
        <div ref={contentRef}>
          <ReactMarkdown
            children={markdownContent}
            remarkPlugins={[remarkGfm]}
            components={renderers}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default MarkdownView;
