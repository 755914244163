import React from "react";

const Copyright = () => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="copyright">
      <div className="row">
        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
          ©{" "}
          <a className="border-bottom" href="#">
            ASDG Portal
          </a>
          , {currentYear} All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Copyright;
