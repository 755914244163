import React, { useState } from "react";
import {
  Button,
  Modal,
  Table,
  Alert,
  Form,
  FormLabel,
  FormControl,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import toast from "react-hot-toast";
import { getCountyName } from "../../utils/getCountiesFromCodes";
import LoadingOverlay from "../layout/components/LoadingOverlay";
import useApiRequest from "../../hooks/useApiRequest";

const TenantsApprovalTableRow = ({ user }) => {
  const [showModal, setShowModal] = useState(false);
  const [verificationChecked, setVerificationChecked] = useState(false);
  const [approvalComplete, setApprovalComplete] = useState(false);
  const [rejectionFeedback, setRejectionFeedback] = useState("");
  const [showRejectionFeedbackError, setShowRejectionFeedbackError] =
    useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const apiRequest = useApiRequest();

  const handleApiResponse = async (response, messageSuccess, messageFail) => {
    if (response.status >= 200 && response.status < 300) {
      setShowModal(false);
      setVerificationChecked(false);
      setRejectionFeedback("");
      setShowRejectionFeedbackError(false);
      setApprovalComplete(true);
      toast.success(messageSuccess);
    } else {
      const errorBody = await response.json();
      const apiError = errorBody.message;
      toast.error(`${messageFail}${apiError && `\n${apiError}`}`);
    }
  };

  const handleApprove = async (uid) => {
    try {
      setIsSubmitting(true);

      const response = await apiRequest({
        url: "/tenant/approve",
        method: "POST",
        body: { uid: uid },
      });

      setIsSubmitting(false);
      handleApiResponse(
        response,
        "Account approved successfully.",
        "Failed to approve account"
      );
    } catch (error) {
      setIsSubmitting(false);
      console.error("An error occurred:", error);
      toast.error("An error occurred while processing.");
    }
  };

  const handleReject = async (uid) => {
    try {
      setIsSubmitting(true);
      const response = await apiRequest({
        url: "/tenant/reject",
        method: "POST",
        body: { uid, reason: rejectionFeedback },
      });

      setIsSubmitting(false);
      handleApiResponse(
        response,
        "Account rejected successfully",
        "Failed to reject account"
      );
    } catch (error) {
      setIsSubmitting(false);
      console.error("An error occurred:", error);
      toast.error("An error occurred while processing.");
    }
  };

  const handleUserAction = async (actionType) => {
    if (actionType === "approve") {
      if (!verificationChecked) {
        toast.error(
          "To approve, you must verify the user's authorization by checking the box."
        );
        return;
      }
      await handleApprove(user.id);
    } else if (actionType === "reject") {
      if (!rejectionFeedback) {
        toast.error(
          "To reject, you must give a reason in the feedback text area"
        );
        setShowRejectionFeedbackError(true);
        return;
      }
      await handleReject(user.id, rejectionFeedback);
    }
  };

  return (
    <>
      <LoadingOverlay loading={isSubmitting} />{" "}
      <tr>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.username}</td>
        <td>
          {user.attributes.tenantType &&
            user.attributes.tenantType[0].toUpperCase()}
        </td>
        <td>
          {user.attributes.organization && user.attributes.organization[0]}
        </td>
        <td>{user.attributes.designation && user.attributes.designation[0]}</td>
        <td>{getCountyName(user.attributes.county[0])}</td>
        <td>{user.attributes.phoneNumber && user.attributes.phoneNumber[0]}</td>
        <td>
          {!user.emailVerified ? (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id="tooltip-disabled">
                  This account has not verified their email address
                </Tooltip>
              }
            >
              <span className="d-inline-block">
                <Button variant="warning" onClick={() => setShowModal(true)}>
                  Review
                </Button>
              </span>
            </OverlayTrigger>
          ) : (
            <Button variant="primary" onClick={() => setShowModal(true)}>
              Review
            </Button>
          )}
        </td>
      </tr>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          setShowRejectionFeedbackError(false);
          setRejectionFeedback("");
          setVerificationChecked(false);
        }}
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Stakeholder Review</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <LoadingOverlay loading={isSubmitting} />{" "}
          {!user.emailVerified && (
            <Alert variant="danger" className="mt-2">
              Awaiting the account user to verify their email to enable review.
              <br />
              You can only reject this request until the email address is
              verified.
            </Alert>
          )}
          {approvalComplete && (
            <Alert variant="success" className="mt-2">
              This account has already been reviewed
            </Alert>
          )}
          <Table bordered hover>
            <tbody>
              <tr>
                <th>Email</th>
                <td>{user.email}</td>
              </tr>
              <tr>
                <th>First Name</th>
                <td>{user.firstName}</td>
              </tr>
              <tr>
                <th>Last Name</th>
                <td>{user.lastName}</td>
              </tr>
              <tr>
                <th>Organization</th>
                <td>{user.attributes.organization}</td>
              </tr>
              <tr>
                <th>Designation</th>
                <td>{user.attributes.designation}</td>
              </tr>
              <tr>
                <th>County</th>
                <td>{getCountyName(user.attributes.county[0])}</td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>{user.attributes.phoneNumber}</td>
              </tr>
              <tr>
                <th>Stakeholder Type</th>
                <td>
                  {user.attributes.tenantType
                    ?.map((type) => type.toUpperCase())
                    .join(", ")}
                </td>
              </tr>
            </tbody>
          </Table>
          {!approvalComplete && (
            <>
              <h5>If Approving</h5>
              <Form.Group controlId="verificationCheckbox" className="mb-3">
                <Form.Check
                  type="checkbox"
                  label="We have contacted the organization and confirmed this user is authorized to represent them."
                  checked={verificationChecked}
                  onChange={(e) => setVerificationChecked(e.target.checked)}
                />
              </Form.Group>
              {!verificationChecked && (
                <Alert variant="warning" className="mt-2">
                  To approve, you must verify the user's authorization by
                  checking the box.
                </Alert>
              )}
              <Button
                variant="success"
                onClick={() => handleUserAction("approve")}
                disabled={
                  !verificationChecked ||
                  isSubmitting ||
                  approvalComplete ||
                  !user.emailVerified
                }
                className="my-1 mx-1 mb-4"
              >
                Approve
              </Button>
              <h5>If Rejecting</h5>
              <Form.Group className="mt-3">
                <FormLabel>Detailed Feedback:</FormLabel>
                <FormControl
                  as="textarea"
                  rows={3}
                  placeholder="Provide detailed feedback on the rejection"
                  value={rejectionFeedback}
                  onChange={(e) => {
                    setShowRejectionFeedbackError(false);
                    setRejectionFeedback(e.target.value);
                  }}
                />
                {showRejectionFeedbackError && (
                  <Alert variant="warning" className="mt-2">
                    Providing detailed feedback is required to reject.
                  </Alert>
                )}
              </Form.Group>
              <Button
                variant="danger"
                onClick={() => handleUserAction("reject")}
                disabled={
                  isSubmitting ||
                  rejectionFeedback.length === 0 ||
                  approvalComplete
                }
                className="my-1 mx-1"
              >
                Reject
              </Button>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TenantsApprovalTableRow;
