// src/App.js
import React from "react";
import AppRoutes from "./routes/AppRoutes";
import "react-phone-number-input/style.css";
import "./scss/app.scss";
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return <AppRoutes />;
}

export default App;
