import React from "react";
import HomeNav from "../../components/Home/HomeNavMenu";
import HomeFooter from "../../components/Home/HomeFooter";

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
  };

  return (
    <>
      <>
        <div className="container-xxl bg-white p-0">
          {/* Navbar Start */}

          <HomeNav />

          {/* Navbar Ends */}

          {/* Footer Starts */}

          <div className="container-xxl bg-white p-0">
            <div className="container-fluid" style={{ marginTop: "40px" }}>
              <div className="container">
                <h2 className="text-center asdg-title">Contact Us </h2>
              </div>

              <div className="container mt-5">
                <div className="row">
                  <div className="col-md-6">
                    <h2>Contact Us</h2>
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          name="name"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          name="email"
                          required
                        />
                      </div>
                      <div className="mb-3">
                        <label htmlFor="subject" className="form-label">
                          Subject
                        </label>
                        <textarea
                          className="form-control"
                          id="subject"
                          name="subject"
                          required
                        />
                      </div>

                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                    </form>
                  </div>
                  <div className="col-md-6">
                    {/* Add your custom Google Map here */}
                    {/* Replace the placeholder with your map */}
                    <div
                      style={{
                        width: "100%",
                        height: "400px",
                        background: "#f0f0f0",
                        border: "1px solid #ccc",
                      }}
                    >
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8040162636303!2d36.812500374132426!3d-1.2919757356306005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f10dca52922c1%3A0x52fbd72bf58303cd!2sKilimo%20House!5e0!3m2!1sen!2ske!4v1703156502898!5m2!1sen!2ske"
                        width="100%"
                        height="400"
                        style={{ border: "0" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <HomeFooter />
          </div>

          {/* Footer Ends */}
        </div>
      </>
    </>
  );
};

export default Contact;
