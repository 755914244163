import { useCallback, useEffect } from "react";
import { useLocation } from "react-router-dom";
import useStore from "../store/store";
import { apiRequest } from "../utils/apiRequest";
import { API_BASE_URL } from "../config";
import useLogout from "./useLogout";

const CACHE_DURATION = 60 * 60 * 1000; // Cache duration in milliseconds. 60 minutes

const useApiRequest = () => {
  const { authData } = useStore((state) => state);
  const accessToken = authData?.access_token;
  const tokenExpiry = authData?.introspection?.exp; // Get token expiry

  const location = useLocation();
  const logout = useLogout();

  const isTokenExpired = useCallback(() => {
    if (!tokenExpiry || !accessToken) return true;
    const expiryTime = new Date(tokenExpiry * 1000);
    const isExpired = new Date() > expiryTime;
    return isExpired;
  }, [tokenExpiry, accessToken]);

  // Periodically check for token expiration
  useEffect(() => {
    const checkTokenExpiration = () => {
      if (isTokenExpired()) {
        const currentPath = location.pathname;
        logout("error-expired-token", currentPath);
        console.log("Access token has expired, logging out...");
      }
    };

    // Set the interval for checking token expiration
    const interval = setInterval(checkTokenExpiration, 1 * 60 * 1000); // Check every 1 minute

    // Cleanup on component unmount
    return () => clearInterval(interval);
  }, [isTokenExpired, location.pathname, logout]);

  const makeRequest = useCallback(
    async ({
      url,
      method = "GET",
      body = null,
      additionalHeaders = {},
      needsToken = true,
      shouldCache = false,
    }) => {
      const getCacheKey = (url) => `apiCache_${encodeURIComponent(url)}`;

      // Utility function to check for cached response and its validity
      const getCachedResponse = (cacheKey) => {
        const cached = sessionStorage.getItem(cacheKey);
        if (cached) {
          const { responseBody, status, statusText, headers, timestamp } =
            JSON.parse(cached);
          if (Date.now() - timestamp < CACHE_DURATION) {
            // Cache is valid
            return new Response(responseBody, {
              status,
              statusText,
              headers: new Headers(headers),
            });
          } else {
            // Cache has expired
            console.log(`Cache for ${cacheKey} has expired`);
            sessionStorage.removeItem(cacheKey);
          }
        }
        return null;
      };

      // Check for cached response
      const cacheKey = getCacheKey(url);

      if (shouldCache) {
        const cachedResponse = getCachedResponse(cacheKey);
        if (cachedResponse) {
          console.log(`Using cached response for ${url}`);
          return cachedResponse;
        }
      }

      // // Check for repeated calls
      // const currentTime = Date.now();
      // if (
      //   lastRequestTime.current[url] &&
      //   currentTime - lastRequestTime.current[url] < 5000
      // ) {
      //   console.log(`Repeated API call to ${url} ignored`);
      //   throw new Error(`Repeated API call to ${url} ignored`);
      // }

      // lastRequestTime.current[url] = currentTime;

      if (needsToken && (!accessToken || isTokenExpired())) {
        const currentPath = location.pathname;
        logout("error-expired-token", currentPath);
        throw new Error("Access token is not available or has expired");
      }

      if (!url.startsWith("http")) {
        url = `${API_BASE_URL}${url}`;
      }

      try {
        const response = await apiRequest(
          url,
          accessToken,
          method,
          body,
          additionalHeaders
        );
        if (!response.ok) {
          // Check for token revocation or invalid token
          if (
            (response.status === 401 || response.status === 403) &&
            location.pathname !== "/login"
          ) {
            // Handle token revocation or invalid token
            logout("error-forbidden", "/login");
            throw new Error("Token has been revoked or is invalid");
          }
        }
        if (shouldCache && response.ok) {
          response
            .clone()
            .text()
            .then((bodyText) => {
              const cacheEntry = {
                responseBody: bodyText,
                status: response.status,
                statusText: response.statusText,
                headers: Array.from(response.headers.entries()),
                timestamp: Date.now(),
              };
              sessionStorage.setItem(cacheKey, JSON.stringify(cacheEntry));
            });
        }

        return response;
      } catch (error) {
        // Additional error handling
        throw error;
      }
    },
    [accessToken, isTokenExpired, location.pathname, logout]
  );

  return makeRequest;
};

export default useApiRequest;
