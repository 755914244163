import { useState, useEffect, useCallback } from "react";
import useApiRequest from "../hooks/useApiRequest";

export const useFetchCategories = () => {
  const [options, setOptions] = useState([]);
  const apiRequest = useApiRequest();

  const fetchCategories = useCallback(async () => {
    try {
      const response = await apiRequest({
        url: "/hive/categories",
        shouldCache: true,
      });
      const data = await response.json();
      setOptions(
        data.map((registry) => ({
          value: registry.id,
          label: registry.name,
          description: registry.description,
        }))
      );
    } catch (error) {
      console.error("Error fetching registries:", error);
    }
  }, [apiRequest]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return { options, fetchRegistries: fetchCategories };
};

export const useFetchSubjects = (selectedCategory) => {
  const [subjects, setSubjects] = useState([]);
  const apiRequest = useApiRequest();

  const fetchSubjects = useCallback(async () => {
    if (!selectedCategory?.value) return;

    try {
      const response = await apiRequest({
        url: `/hive/categories/${selectedCategory.value}`,
        shouldCache: true,
      });
      const data = await response.json();
      setSubjects(data);
    } catch (error) {
      console.error("Error fetching category data:", error.message);
    }
  }, [selectedCategory?.value, apiRequest]); // Dependencies

  useEffect(() => {
    fetchSubjects();
  }, [fetchSubjects]); // Dependency on fetchSubjects

  return { subjects, fetchSubjects };
};

export const useFetchTopics = (categoryId, subject) => {
  const [dataTopic, setDataTopic] = useState([]);
  const apiRequest = useApiRequest();

  const fetchTopicsForSubject = useCallback(async () => {
    if (!categoryId || !subject) {
      return []; // Return an empty array if preconditions are not met
    }

    try {
      const response = await apiRequest({
        url: `/hive/categories/${categoryId}/${subject}`,
        shouldCache: true,
      });
      const topics = await response.json();
      setDataTopic(topics || []); // Ensure topics is an array
      return topics || []; // Return the topics for external use
    } catch (error) {
      console.error("Error fetching topics:", error.message);
      return []; // Return an empty array on error
    }
  }, [categoryId, subject, apiRequest]);

  useEffect(() => {
    fetchTopicsForSubject();
  }, [fetchTopicsForSubject]);

  return { dataTopic, fetchTopics: fetchTopicsForSubject };
};
