import React from "react";
import CustomNavLink from "./CustomNavLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faList,
  faDatabase,
  faBook,
  // faCog, // Uncomment if you're using it
  // faBook, // Uncomment if you're using it
  // faLaptop, // Uncomment if you're using it
} from "@fortawesome/free-solid-svg-icons";

const navItems = [
  { to: "/u/new-data-request", icon: faPlus, label: "New Dataset Request" },
  { to: "/u/data-requests", icon: faList, label: "My Data Requests" },
  { to: "/u/api-access", icon: faDatabase, label: "API Data Access" },
  { to: "/u/user-guide", icon: faBook, label: "User Guide" },
  // Add more items here as needed
  // { to: '/settings', icon: faCog, label: 'Settings' }, // Uncomment if needed
  // { to: '/resources', icon: faBook, label: 'Resources' }, // Uncomment if needed
  // { to: '/developer-portal', icon: faLaptop, label: 'Developer Portal' }, // Uncomment if needed
];

const SidebarUser = () => {
  return (
    <ul
      className="navbar-nav bg-primary sidebar sidebar-dark accordion ps-2"
      id="accordionSidebar"
    >
      {/* Nav Items */}
      {navItems.map((item, index) => (
        <CustomNavLink key={index} to={item.to}>
          <FontAwesomeIcon icon={item.icon} className="pe-2 ps-2" />
          <span>{item.label}</span>
        </CustomNavLink>
      ))}
    </ul>
  );
};

export default SidebarUser;
