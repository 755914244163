import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Modal,
  Form,
  ListGroup,
  Spinner,
  Container,
  Row,
  Col,
  Alert,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import countiesData from "../../data/counties_data.json";
import useApiRequest from "../../hooks/useApiRequest";
import {
  useFetchCategories,
  useFetchSubjects,
  useFetchTopics,
} from "../../hooks/dataHooks";
import {
  CategorySelect,
  DataSubjectSelect,
  DataTopicTabs,
  DataRequestReason,
  DSARadioSelect,
  DSAFilePicker,
  FlashMessage,
  DataRequestSummaryModal,
} from "../../components/FormComponents";
import LoadingOverlay from "../../components/layout/components/LoadingOverlay";
import TermsAndConditionsModal from "../../components/TermsAndConditionsModal";
import useStore from "../../store/store";

const NewDataRequest = () => {
  const navigate = useNavigate();
  const apiRequest = useApiRequest();
  const { user } = useStore((state) => ({
    user: state.user,
  }));

  const isCountyAccount = user.attributes.tenantType[0] === "county";

  // State hooks for form fields and UI control
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [requestReason, setRequestReason] = useState("");
  const [selectedCounties, setSelectedCounties] = useState([]);
  const [flashMessage, setFlashMessage] = useState("");
  const [hasSignedDSA, setHasSignedDSA] = useState(true);
  const [isSubjectsLoading, setIsSubjectsLoading] = useState(false);
  const [isTopicsLoading, setIsTopicsLoading] = useState(false);
  const [metadataData, setMetadataData] = useState({});
  const [datasetsMapData, setDatasetsMapData] = useState({});
  const [selectedDataset, setSelectedDataset] = useState("");
  const [datasetFields, setDatasetFields] = useState([]);
  const [topics, setTopics] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  // State hooks for modal visibility
  const [showModal, setShowModal] = useState(false);
  const [showFieldsModal, setShowFieldsModal] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [isLoadingInitialData, setIsLoadingInitialData] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [selectAllCounties, setSelectAllCounties] = useState(false);
  const [unmetConditions, setUnmetConditions] = useState([]);

  // Prepare your selections to pass to the DataRequestSummaryModal
  const selections = {
    selectedCategory,
    selectedSubject,
    selectedTopics,
    selectedCounties,
    requestReason,
    hasSignedDSA,
    selectedFields,
  };

  // Hooks for fetching dropdown options
  const { options, fetchRegistries } = useFetchCategories();
  const { subjects, fetchSubjects } = useFetchSubjects(selectedCategory);
  const { fetchedTopics, fetchTopics } = useFetchTopics(
    selectedCategory?.value,
    selectedSubject
  );

  useEffect(() => {
    setIsLoadingInitialData(true); // Start loading before all fetches

    const loadInitialData = async () => {
      try {
        await Promise.all([
          fetchRegistries(),
          fetchMetadata(),
          fetchDatasetsMap(),
        ]);
        // All fetch operations have completed successfully at this point
      } catch (error) {
        // Handle any errors from the fetch operations here
      } finally {
        setIsLoadingInitialData(false); // End loading after all fetches
      }
    };

    loadInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty array ensures this runs only once on mount

  useEffect(() => {
    const updateSubjects = async () => {
      setSelectedSubject(null);
      setSelectedTopics([]);
      setTopics([]);
      setIsSubjectsLoading(true);
      await fetchSubjects();
      setIsSubjectsLoading(false);
    };
    updateSubjects();
  }, [fetchSubjects, selectedCategory]);

  useEffect(() => {
    const updateTopics = async () => {
      if (selectedSubject) {
        setIsTopicsLoading(true);
        await fetchTopics()
          .then((fetchedTopics) => {
            setTopics(fetchedTopics);
            setIsTopicsLoading(false);
          })
          .catch((error) => {
            setTopics([]); // Reset topics on error
            setIsTopicsLoading(false);
          });
      } else {
        setTopics([]); // Reset topics if no subject is selected
      }
    };

    updateTopics();
  }, [fetchTopics, selectedSubject]);

  // Update selectedDataset when selectedCategory or selectedSubject changes
  useEffect(() => {
    if (
      selectedCategory &&
      selectedSubject &&
      datasetsMapData[selectedCategory.value]
    ) {
      const categoryDatasets = datasetsMapData[selectedCategory.value];
      const datasetName = categoryDatasets[selectedSubject];
      setSelectedDataset(datasetName || "");
      // Get fields from metadata
      const datasetMetadata = metadataData.find(
        (data) => data.objectname === selectedDataset
      );
      const fields = datasetMetadata?.columns?.columns || [];
      setDatasetFields(fields.map((field) => ({ ...field })));
    } else {
      setSelectedDataset("");
      setDatasetFields([]);
    }
  }, [
    selectedCategory,
    selectedSubject,
    datasetsMapData,
    metadataData,
    selectedDataset,
  ]);

  useEffect(() => {
    const conditions = [];
    if (!selectedCategory) {
      conditions.push("A category must be selected.");
    }
    if (!selectedSubject) {
      conditions.push("A category must be selected.");
    }
    if (selectedTopics.length === 0) {
      conditions.push("At least one topic must be selected.");
    }
    if (selectedCounties.length === 0) {
      conditions.push("At least one county must be selected.");
    }
    if (selectedFields.length === 0) {
      conditions.push("At least one field must be selected.");
    }
    if (requestReason.length === 0) {
      conditions.push("A reason for the request must be provided.");
    }
    if (hasSignedDSA === null || !hasSignedDSA) {
      conditions.push("You must have an approved Data Sharing Agreement.");
    }
    if (document.getElementById("dsa")?.files?.length === 0) {
      conditions.push(
        "You must attach the Data Sharing Agreement PDF document."
      );
    }
    if (!termsAccepted) {
      conditions.push("You must accept the terms and conditions.");
    }

    setUnmetConditions(conditions);
  }, [
    selectedCategory,
    selectedSubject,
    selectedTopics,
    selectedCounties,
    requestReason,
    hasSignedDSA,
    selectedFields,
    termsAccepted,
  ]); // Add other dependencies as needed

  useEffect(() => {
    if (isCountyAccount) {
      const accountCountyCode = parseInt(user.attributes.county[0]);
      const foundCounty = countiesData.find(
        (c) => c.code === accountCountyCode
      );
      if (foundCounty) {
        setSelectedCounties([foundCounty.code]); // Preselect the county
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCountyAccount, user.attributes.county, countiesData]);

  // Functions for modal handlers
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleTermsAccept = (accepted) => {
    setTermsAccepted(accepted);
  };

  const handleSubjectChange = (subject) => {
    setSelectedSubject(subject);
  };

  const handleTopicChange = (selectedSubject, topic, isChecked, topicsList) => {
    if (topicsList) {
      setSelectedTopics(topicsList);
    } else {
      setSelectedTopics((prevTopics) => {
        if (isChecked) {
          // Add the topic if it's checked and not already in the list
          return prevTopics.includes(topic)
            ? prevTopics
            : [...prevTopics, topic];
        } else {
          // Remove the topic if it's unchecked
          return prevTopics.filter((t) => t !== topic);
        }
      });
    }
  };

  const validateAndShowSummary = () => {
    setShowSummaryModal(true);
  };

  const handleConfirmSubmit = () => {
    setShowSummaryModal(false);
    handleDataSubmit();
  };

  const handleDataSubmit = async () => {
    // Create a new FormData object
    const formData = new FormData();

    if (!selectedCategory) {
      setFlashMessage("Error: Category selection is required.");
      return;
    }
    if (!selectedSubject) {
      setFlashMessage("Error: Subject selection is required.");
      return;
    }
    if (selectedTopics.length === 0) {
      setFlashMessage("Error: Topics selection is required.");
      return;
    }
    if (requestReason.length === 0) {
      setFlashMessage("Error: Data request reason should not be empty");
      return;
    }
    if (selectedFields.length === 0) {
      setFlashMessage("Error: Select Data Columns Should not be Empty");
      return;
    }
    if (!hasSignedDSA) {
      setFlashMessage("Error: You need to attach an approved DSA");
      return;
    }

    // Append data to the FormData object
    formData.append("registry", selectedCategory?.value);
    formData.append(
      "subjects",
      JSON.stringify([
        {
          title: selectedSubject,
          topics: selectedTopics,
        },
      ])
    );

    // Construct fields data with "masked" property set to false
    const fieldsData = selectedFields.map((field) => ({
      title: field.name,
      masked: field.masked,
      alias: field.alias,
    }));

    // Append fields data
    formData.append("fields", JSON.stringify(fieldsData));

    formData.append("reason", requestReason);

    // Handle the file input for Data Sharing Agreement
    const fileInput = document.getElementById("dsa");
    if (hasSignedDSA) {
      if (fileInput.files.length > 0) {
        const file = fileInput.files[0];
        if (file.size > 10485760) {
          // 10 MB in bytes
          setFlashMessage("File size should not exceed 10MB.");
          return;
        }
        formData.append("dsa", file, file.name);
      } else {
        setFlashMessage("Error: Please attach the Data Sharing Agreement.");
        return;
      }
    }

    // Join the selected counties into a comma-separated string
    formData.append("county", selectedCounties.join(","));

    try {
      setIsSubmitting(true);
      const response = await apiRequest({
        url: "/data-requests/create",
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        setIsSubmitting(false);
        setFlashMessage(
          "Successfully Sent Data Request.\nNavigating you to your data requests."
        );
        setTimeout(() => {
          navigate("/u/data-requests");
        }, 2000);
      } else {
        setIsSubmitting(false);
        const errorMessage = `HTTP error! Status: ${response.status}`;
        console.error(errorMessage);
        const responseData = await response.json();
        console.error("Response Data:", responseData);
        setFlashMessage("Request failed.");
      }
    } catch (error) {
      setIsSubmitting(false);
      console.error("An error occurred:", error.message);
      if (error.message.includes("Repeated API call")) {
        // Handle repeated call error specifically, if needed
      } else {
        // Handle other errors
        setFlashMessage("An error occurred.");
      }
    }
  };

  // Function to fetch /hive/metadata
  const fetchMetadata = async () => {
    try {
      const response = await apiRequest({
        url: "/hive/metadata",
        method: "GET",
        shouldCache: true,
      });
      const data = await response.json();
      if (response.ok) {
        setMetadataData(data);
      } else {
        console.error("HTTP error! Status:", response.status);
        console.error("Response Data:", data);
      }
    } catch (error) {
      console.error("An error occurred:", error.message);
      if (error.message.includes("Repeated API call")) {
        // Handle repeated call error specifically, if needed
      } else {
        // Handle other errors
        setFlashMessage("An error occurred fetching metadata.");
      }
    }
  };

  // Function to fetch /hive/datasets-map
  const fetchDatasetsMap = async () => {
    try {
      const response = await apiRequest({
        url: "/hive/datasets-map",
        method: "GET",
        shouldCache: true,
      });
      const data = await response.json();
      if (response.ok) {
        setDatasetsMapData(data);
      } else {
        console.error("HTTP error! Status:", response.status);
        console.error("Response Data:", data);
      }
    } catch (error) {
      console.error("An error occurred fetching Datasets map.", error.message);
      if (error.message.includes("Repeated API call")) {
        // Handle repeated call error specifically, if needed
      } else {
        // Handle other errors
        setFlashMessage("An error occurred fetching Datasets map.");
      }
    }
  };

  const handleSelectAllCountiesChange = (e) => {
    setSelectAllCounties(e.target.checked);
    if (e.target.checked) {
      setSelectedCounties(countiesData.map((county) => county.code));
    } else {
      setSelectedCounties([]);
    }
  };

  const handleCountyChange = (e) => {
    let value = parseInt(e.target.value);
    setSelectedCounties((current) =>
      current.includes(value)
        ? current.filter((code) => code !== value)
        : [...current, value]
    );
  };

  const renderDescriptionCard = (title, description) => (
    <Card className="mb-2 mb-lg-4 shadow-sm">
      <Card.Body className="p-2 p-lg-3">
        <Card.Title className="header-sm-lg">
          <FontAwesomeIcon icon={faInfoCircle} className="me-2" />
          {title}
        </Card.Title>
        <Card.Text className="text-muted fst-italic text-sm-lg">
          {description}
        </Card.Text>
      </Card.Body>
    </Card>
  );

  return (
    <Container fluid>
      {isLoadingInitialData && (
        <LoadingOverlay loading={isLoadingInitialData} />
      )}
      <Row>
        <Col xs={12} sm={12} md={12} lg={8} className="p-0">
          <Card>
            <Card.Header className="py-3">
              <h6 className="m-0 font-weight-bold">
                Create and submit a new dataset request
              </h6>
            </Card.Header>
            <Card.Body className="p-3 p-lg-4" style={{ position: "relative" }}>
              {isSubmitting && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 1050, // Use a high z-index to ensure it covers other elements
                    backgroundColor: "rgba(255, 255, 255, 0.75)", // Semi-transparent overlay
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
              <p>
                <b>Welcome to the Data Access Portal. </b>The registries offer
                aggregated datasets encompassing a wide array of categories.
                Please request the data you need below.
              </p>
              <br />
              <form>
                <CategorySelect
                  options={options}
                  selectedOption={selectedCategory}
                  onChange={setSelectedCategory}
                  isLoadingSubjects={isSubjectsLoading}
                  isLoadingTopics={isTopicsLoading}
                />
                {selectedCategory && (
                  <div className="d-block d-lg-none">
                    {renderDescriptionCard(
                      selectedCategory?.label,
                      selectedCategory?.description
                    )}
                  </div>
                )}

                {selectedCategory && (
                  <div className="d-block d-lg-none mt-4">
                    {renderDescriptionCard(
                      "Data Subjects",
                      "This is the next level grouping of data below a category into subjects e.g., in the Actor Registry there will be information about farmers, extension workers, etc."
                    )}
                  </div>
                )}
                <DataSubjectSelect
                  subjects={subjects}
                  selectedSubject={selectedSubject}
                  onChange={handleSubjectChange}
                  isLoadingSubjects={isSubjectsLoading}
                  isLoadingTopics={isTopicsLoading}
                />

                {selectedSubject && (
                  <div className="d-block d-lg-none mt-2">
                    {renderDescriptionCard(
                      "Data Topics",
                      "This is the lower-level grouping below subject to describe the topic in a subject e.g., in the Actor Registry there will be information about farmers, about livestock farmers, about crop farmers, about fisherfolk etc."
                    )}
                  </div>
                )}
                <DataTopicTabs
                  selectedSubject={selectedSubject}
                  topics={topics}
                  fetchedTopics={fetchedTopics}
                  selectedTopics={selectedTopics}
                  onTopicChange={handleTopicChange}
                  isLoadingTopics={isTopicsLoading}
                />
                {selectedCategory && selectedSubject && (
                  <>
                    <h6 className="mt-4">
                      Select Counties: (<small>Required</small>)
                    </h6>
                    {isCountyAccount ? (
                      <Alert variant="info">
                        This account is associated with your county and
                        cannot select different counties.
                      </Alert>
                    ) : (
                      <>
                        <Button
                          variant={
                            selectedCounties.length === 0
                              ? "warning"
                              : "primary"
                          }
                          onClick={handleShowModal}
                          disabled={isCountyAccount} // Disable if it's a county account
                        >
                          Select Counties
                        </Button>
                        <Modal
                          show={showModal}
                          onHide={handleCloseModal}
                          size="lg"
                        >
                          <Modal.Header closeButton>
                            <Modal.Title>Select Counties</Modal.Title>
                          </Modal.Header>
                          <Modal.Body
                            style={{
                              maxHeight: "80vh",
                              overflowY: "auto",
                            }}
                          >
                            <Form>
                              <Form.Check
                                type="checkbox"
                                id="select-all-counties"
                                label="Select All"
                                onChange={handleSelectAllCountiesChange}
                                checked={
                                  selectedCounties.length ===
                                  countiesData.length
                                }
                                className="mb-2"
                                style={{ fontWeight: "bold", color: "#347925" }}
                              />
                              {countiesData
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .map((county) => (
                                  <Form.Check
                                    type="checkbox"
                                    id={`county-${county.code}`}
                                    label={county.name}
                                    key={county.code}
                                    value={county.code}
                                    checked={selectedCounties.includes(
                                      county.code
                                    )}
                                    onChange={handleCountyChange}
                                    disabled={isCountyAccount} // Disable checkboxes if it's a county account
                                  />
                                ))}
                            </Form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="success"
                              onClick={handleCloseModal}
                            >
                              Save
                            </Button>
                          </Modal.Footer>
                        </Modal>
                      </>
                    )}

                    <ListGroup className="mt-2">
                      {selectedCounties.map((code) => {
                        const county = countiesData.find(
                          (c) => c.code === code
                        );
                        return (
                          <ListGroup.Item
                            key={code}
                            className="d-flex justify-content-between align-items-center"
                          >
                            {county.name}
                            {!isCountyAccount && (
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() =>
                                  setSelectedCounties((current) =>
                                    current.filter((c) => c !== code)
                                  )
                                }
                              >
                                Remove
                              </Button>
                            )}
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                  </>
                )}

                {selectedCategory && selectedSubject && (
                  <>
                    <h6 className="mt-4">
                      Select Fields: (<small>Required</small>)
                    </h6>
                    <Button
                      variant={
                        selectedFields.length === 0 ? "warning" : "primary"
                      }
                      onClick={() => setShowFieldsModal(true)}
                    >
                      Select Data Columns
                    </Button>

                    {/* Fields Modal */}
                    <Modal
                      show={showFieldsModal}
                      onHide={() => setShowFieldsModal(false)}
                      size="lg"
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>Select Data Columns</Modal.Title>
                      </Modal.Header>
                      <Modal.Body
                        style={{
                          maxHeight: "80vh",
                          overflowY: "auto",
                        }}
                      >
                        <Form.Check
                          type="checkbox"
                          label="Select All"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setSelectedFields(
                                datasetFields.map((field) => ({
                                  name: field.name,
                                  alias: field.alias,
                                  masked: field.masked,
                                }))
                              );
                            } else {
                              setSelectedFields([]);
                            }
                          }}
                          checked={
                            selectedFields.length === datasetFields.length
                          }
                          className="mb-2"
                          style={{ fontWeight: "bold", color: "#347925" }} // Example style to make it distinguishable
                        />
                        {datasetFields.map((field, index) => (
                          <Form.Check
                            key={index}
                            type="checkbox"
                            label={field.alias} // Displaying the alias for clarity
                            checked={selectedFields.some(
                              (f) => f.name === field.name
                            )} // Adjusted check condition
                            onChange={(e) => {
                              const fieldObj = {
                                name: field.name,
                                alias: field.alias,
                                masked: field.masked,
                              }; // Create an object for the field
                              const newSelectedFields = e.target.checked
                                ? [...selectedFields, fieldObj] // Add object if checked
                                : selectedFields.filter(
                                    (f) => f.name !== field.name
                                  ); // Remove object if unchecked
                              setSelectedFields(newSelectedFields); // Update state
                            }}
                          />
                        ))}
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          variant="success"
                          onClick={() => setShowFieldsModal(false)}
                        >
                          Save
                        </Button>
                      </Modal.Footer>
                    </Modal>

                    <ListGroup className="mt-2">
                      {selectedFields.map((field) => (
                        <ListGroup.Item
                          key={field.alias}
                          className="d-flex justify-content-between align-items-center"
                        >
                          {field.alias}
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() =>
                              setSelectedFields((current) =>
                                current.filter((f) => f.name !== field.name)
                              )
                            }
                          >
                            Remove
                          </Button>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  </>
                )}

                <div className="mb-3"></div>
                <DataRequestReason
                  value={requestReason}
                  onChange={setRequestReason}
                />
                <DSARadioSelect
                  hasSignedDSA={hasSignedDSA}
                  onChange={setHasSignedDSA}
                />
                {hasSignedDSA && <DSAFilePicker />}

                <Button
                  variant={!termsAccepted ? "warning" : "primary"}
                  onClick={() => setShowTermsModal(true)}
                  className="mb-2"
                >
                  <b>View Terms and Conditions</b>
                </Button>
                <br />
                <TermsAndConditionsModal
                  show={showTermsModal}
                  onHide={() => setShowTermsModal(false)}
                  onChanged={handleTermsAccept}
                />
                {!termsAccepted && (
                  <Alert variant="danger">
                    Please read and acknowledge the terms and conditions by
                    ticking the checkbox at the bottom.
                  </Alert>
                )}
                <FlashMessage
                  variant={
                    flashMessage?.toLowerCase().startsWith("error")
                      ? "danger"
                      : "info"
                  }
                  message={flashMessage}
                />
                <DataRequestSummaryModal
                  show={showSummaryModal}
                  onHide={() => setShowSummaryModal(false)}
                  selections={selections}
                  onSubmit={handleConfirmSubmit}
                />

                {unmetConditions.length > 0 && (
                  <Alert variant="warning">
                    <p>
                      <b>Please address the following to submit:</b>
                    </p>
                    <ul>
                      {unmetConditions.map((condition, index) => (
                        <li key={index}>{condition}</li>
                      ))}
                    </ul>
                  </Alert>
                )}

                <Button
                  variant="success"
                  onClick={() => validateAndShowSummary()}
                  disabled={
                    hasSignedDSA === null ||
                    !hasSignedDSA ||
                    !selectedCategory ||
                    !selectedSubject ||
                    selectedTopics.length === 0 ||
                    selectedCounties.length === 0 ||
                    requestReason.length === 0 ||
                    selectedFields.length === 0 ||
                    document.getElementById("dsa")?.files?.length === 0 ||
                    !termsAccepted
                  }
                >
                  Review and Submit Request
                </Button>
              </form>
            </Card.Body>
          </Card>
        </Col>
        {selectedCategory && (
          <Col lg={4} className="d-none d-lg-block">
            <Container>
              <Row className="mt-5">
                {renderDescriptionCard(
                  selectedCategory.label,
                  selectedCategory.description
                )}
                {renderDescriptionCard(
                  "Data Subjects",
                  "This is the next level grouping of data below a category into subjects e.g., in the Actor Registry there will be information about farmers, extension workers, etc."
                )}
                {selectedSubject &&
                  renderDescriptionCard(
                    "Data Topics",
                    "This is the lower-level grouping below subject to describe the topic in a subject e.g., in the Actor Registry there will be information about farmers, about livestock farmers, about crop farmers, about fisherfolk etc."
                  )}
              </Row>
            </Container>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default NewDataRequest;
