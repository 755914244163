// store.js
import { create } from "zustand";
import { persist } from "zustand/middleware";

const useStore = create(
  persist(
    (set) => ({
      authData: null,
      user: null,
      isAuthenticated: false,
      isAdmin: false,
      isSuperAdmin: false,
      isApproved: false,
      setAuthData: (data) => set({ authData: data }),
      setUser: (user) => set({ user }),
      setIsAuthenticated: (isAuthenticated) => set({ isAuthenticated }),
      setAdmin: (isAdmin) => set({ isAdmin }),
      setSuperAdmin: (isSuperAdmin) => set({ isSuperAdmin }),
      setIsApproved: (isApproved) => set({ isApproved }),

      reset: () =>
        set({
          authData: null,
          user: null,
          isAuthenticated: false,
          isAdmin: false,
          isSuperAdmin: false,
          isApproved: false,
        }),
    }),
    {
      name: "auth-store", // unique name for the persisted state
      getStorage: () => localStorage, // define the storage type
    }
  )
);

export default useStore;
