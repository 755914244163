import React, { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Form,
  Button,
  Alert,
  Spinner,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import toast from "react-hot-toast";
import logo from "../logo_kenya.png";
import useApiRequest from "../hooks/useApiRequest";

const ForgotPassword = () => {
  const apiRequest = useApiRequest();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      setError("");
      try {
        const response = await apiRequest({
          url: "/auth/forgot-password",
          method: "POST",
          body: { email },
          needsToken: false,
        });

        if (!response.ok) {
          toast.error("Failed to send password reset email. Please try again.");
          console.error(
            "Failed to send password reset email. Please try again."
          );
        } else {
          toast.success(
            "Password reset email sent! Please check your email, including your spam/junk folders."
          );
          setSuccess(true);
          setEmail("");
        }
      } catch (err) {
        toast.error("Failed to send password reset email. Please try again.");
        console.error("Error:", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    },
    [apiRequest, email]
  );

  return (
    <Container
      className="min-vh-100 min-vw-100 d-flex align-items-center"
      style={{ backgroundColor: "#f0f0f0" }}
    >
      <Row className="justify-content-center w-100">
        <Col md={4}>
          <Card>
            <Card.Body>
              <div className="text-center mb-4">
                <img
                  src={logo}
                  alt="Logo"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
              <h4 className="text-center mb-4">Forgot ASDG Password</h4>
              {!success && (
                <Form onSubmit={handleSubmit}>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={handleEmailChange}
                      required
                    />
                  </Form.Group>
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={loading || success}
                    className="btn-block mt-2"
                  >
                    {loading ? (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />{" "}
                        Sending...
                      </>
                    ) : (
                      "Reset Password"
                    )}
                  </Button>
                </Form>
              )}
              {success && (
                <Alert variant="success" className="mt-3">
                  The email has been successfully sent, please check your
                  spam/junk folders
                </Alert>
              )}
              {error && (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              )}
              <div className="mt-3 text-center">
                <Link
                  to="/login"
                  className={`btn ${success ? "btn-primary" : "btn-link"}`}
                >
                  Back to Login
                </Link>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ForgotPassword;
