import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../../store/store";
import aqua from "../../../src/images/aquatic-chains.png";
import dfg from "../../../src/images/dfg.jpg";
import farmcrop from "../../../src/images/crops-chains.png";
import livestock from "../../../src/images/animals-chain.png";
import about from "../../../src/images/grazers.jpg";
import datapic from "../../../src/images/farmerdata.jpg";
import konzalogo from "../../../src/images/konzalogo.png";
import tbilogo from "../../../src/images/tbilogo.png";
import gizlogo from "../../../src/images/gizlogo.png";
import agralogo from "../../../src/images/agra.png";
import kalrologo from "../../../src/images/kalrologo.png";
import tmalogo from "../../../src/images/tma.png";
import Carousel from "react-bootstrap/Carousel";
import HomeNav from "../../components/Home/HomeNavMenu";
import HomeFooter from "../../components/Home/HomeFooter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import BgVideo from '../../components/media/introasdgvid.mp4'

const data = [
  {
    image: require("../../images/farmers.jpg"),
    caption: "Research & Innovation",
    description:
      "Strengthen research and innovation to launch priority digital and data use cases that drive better decision-making and performance management.",
  },
  {
    image: require("../../images/asdgfarmerslide-2.jpg"),
    caption: "Leveraging on Data Governance Framework",
    description:
      "The data governance framework is hereby set out to cover data access, security, sharing, and ownership aspects of the data collected and processed in use cases ",
  },
  {
    image: require("../../images/crop-farmer.jpg"),
    caption: "Our Aim",
    description:
      "To have an Agriculture data exchange and ecosystem, that establishes standards & protocols to provide access to quality data and offer analytical capabilities for stakeholders to better support production and better intelligence to farmers for increased yields.",
  },
];

const Home = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const { isAuthenticated, isAdmin, isApproved } = useStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    isAdmin: state.isAdmin,
    isApproved: state.isApproved,
  }));
  const navigate = useNavigate();

  const handleDashboardClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    if (!isApproved) {
      navigate("/unverified");
    } else {
      const redirectRoute = isAdmin
        ? "/a/stakeholder-approval"
        : "/u/new-data-request";
      navigate(redirectRoute);
    }
  };

  return (
    <>
      <>
    
       
       
          {/* Spinner Start */}

          {/* Spinner End */}
          {/* Navbar Start */}

          <HomeNav />

          {/* Navbar End */}
          {/* Header Start */}
    



<div className="ome-video-bg">
<video src={BgVideo} autoPlay muted loop className="video-bg" />




  
  
<div className="news-flash">
  <h1 className="col-sm-12" style={{ margin: '0 auto', textAlign: 'center' }}>
    Access Crops, Aquatic and Livestock Value Chains Data
  </h1>
</div>


</div>



          {/* Header End */}
          {/* Search Start */}
      
          <div
  className="container-fluid"
  data-wow-delay="0.1s"
  style={{ marginTop: "-180px", position: "relative", zIndex: "1" }}
>
 
 
</div>

          {/* Search End */}
          {/* Category Start */}
          <div className="container-xxl py-5"   style={{ marginTop: "-370px", position: "relative", zIndex: "1" }}>
            <div className="container">
              <div
                className="text-center mx-auto mb-5 wow fadeInUp"
                data-wow-delay="0.1s"
                style={{ maxWidth: 600 }}
              >
             
             
                <p></p>
              </div>
              <div className="row g-4">
                <div
                  className="col-lg-4 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
                  <Link
                 
                 
                    className="cat-item d-block bg-light text-center rounded p-3"
                  >
                    <div className="rounded p-4">
                      <div className="icon mb-3">
                        <img className="img-fluid" src={livestock} alt="Icon" />
                      </div>
                      <h6 className="title-links">
                      State Department for Livestock Development
                      </h6>
                    </div>
                  </Link>
                </div>
                <div
                  className="col-lg-4 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.3s"
                >
                  <Link
                  
                  
                    className="cat-item d-block bg-light text-center rounded p-3"
                  >
                    <div className="rounded p-4">
                      <div className="icon mb-3">
                        <img className="img-fluid" src={farmcrop} alt="Icon" />
                      </div>
                      <h6 className="title-links">
                      State Department for Agriculture
                      </h6>
                    </div>
                  </Link>
                </div>
                <div
                  className="col-lg-4 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.5s"
                >
                  <Link
               
               
                    className="cat-item d-block bg-light text-center rounded p-3"
                  >
                    <div className="rounded p-4">
                      <div className="icon mb-3">
                        <img className="img-fluid" src={aqua} alt="Icon" />
                      </div>
                      <h6 className="title-links">
                      State Department for Fisheries and Blue Economy
                        
                      </h6>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* Category End */}
          {/* About Start */}


          <div className="container-xxl py-5"   style={{ marginTop: "2px", position: "relative", zIndex: "1" }}>
            <div className="container">
           
           
              <div className="row g-4">
                <div
                  className="col-lg-4 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.1s"
                >
             
             
                    <div className="rounded p-4">
                      <div className="icon mb-3">
                        <img className="img-fluid" src={about} alt="Icon" />
                      </div>
                      <h6 className="title-links">
        
        
                      </h6>
                      <Link
          onClick={handleDashboardClick}
          className="btn btn-primary py-3 px-5 mt-3"
        >
          Access All Agriculture Sector Data
        </Link>
                    </div>
                
                </div>
                <div
                  className="col-lg-4 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.3s"
                >
               
               
                    <div className="rounded p-4">
                      <div className="icon mb-3">
                        <img className="img-fluid" src={datapic} alt="Icon" />
                      </div>
                      <h3 className="asdg-title">Our Commitment to Data Privacy</h3>
        <p className="mb-4">
      
      
        </p>

     
                    </div>
               
                </div>
                <div
                  className="col-lg-4 col-sm-6 wow fadeInUp"
                  data-wow-delay="0.5s"
                >
               
               
                    <div className="rounded p-4">
                      <div className="icon mb-3">
                        <img className="img-fluid" src={dfg} alt="Icon" />
                      </div>
                      <h6 className="title-links">
                      <a
                    className="btn btn-primary py-3 px-5 mt-3"
                    target="_blank"
                    href="https://kilimo.go.ke/wp-content/uploads/2022/04/MoALFC-Data-Governance-Framework-2022.pdf"
                  >
                    Download Data Governance Framework
                  </a>
                        
                      </h6>
                    </div>
               
               
                </div>
              </div>
            </div>
          </div>






        
          {/* About End */}

          {/* Privacy Start */}
       
       
          {/* Privacy End */}
          {/* Property List Start */}
          <div className="container-xxl py-5">
          <h1 className="asdg-title" style={{textAlign:"center"}}>Our Partners</h1>
            <div className="container">
              <div className="row g-0 gx-5 align-items-end">
                <div className="col-lg-6">
                  <div
                    className="text-start mx-auto mb-5 wow slideInLeft"
                    data-wow-delay="0.1s"
                  >
                   
                  </div>
                </div>
              </div>
              <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                  <div className="row g-4">
                    <div
                      className="col-lg-3 col-md-6 wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <div className="property-item rounded overflow-hidden">
                        <div className="position-relative overflow-hidden">
                          <a
                            href="https://www.trademarkafrica.com/"
                            target="_blank"
                          >
                            <img className="img-fluid" alt="" src={tmalogo} style={{ width: '255px', height: '225px' }} />
                          </a>
                        
                        
                        </div>
                      </div>{" "}
                      <br />
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <div className="property-item rounded overflow-hidden">
                        <div className="position-relative overflow-hidden">
                          <a
                            href="https://www.institute.global/"
                            target="_blank"
                          >
                            <img className="img-fluid" src={tbilogo}  style={{ width: '255px', height: '225px' }} alt="" />
                          </a>
                        
                        
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow fadeInUp"
                      data-wow-delay="0.5s"
                    >
                      <div className="property-item rounded overflow-hidden">
                        <div className="position-relative overflow-hidden">
                          <a
                            href="https://www.giz.de/en/html/index.html"
                            target="_blank"
                          >
                            <img className="img-fluid" src={gizlogo}  style={{ width: '255px', height: '225px' }} alt="" />
                          </a>
                         
                         
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow fadeInUp"
                      data-wow-delay="0.5s"
                    >
                      <div className="property-item rounded overflow-hidden">
                        <div className="position-relative overflow-hidden">
                          <a href="https://www.kalro.org/" target="_blank">
                            <img className="img-fluid" src={kalrologo}  style={{ width: '255px', height: '225px' }} alt="" />
                          </a>
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-2" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-4 col-md-6"></div>
                  </div>
                </div>
              </div>

              <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                  <div className="row g-4">
                    <div
                      className="col-lg-3 col-md-6 wow fadeInUp"
                      data-wow-delay="0.1s"
                    >
                      <div className="property-item rounded overflow-hidden">
                        <div className="position-relative overflow-hidden">
                          <a
                            href="https://www.trademarkafrica.com/"
                            target="_blank"
                          >
                            <img className="img-fluid" alt="" src={konzalogo} style={{ width: '255px', height: '225px' }}  />
                          </a>
                         
                         
                        </div>
                      </div>{" "}
                      <br />
                    </div>
                    <div
                      className="col-lg-3 col-md-6 wow fadeInUp"
                      data-wow-delay="0.3s"
                    >
                      <div className="property-item rounded overflow-hidden">
                        <div className="position-relative overflow-hidden">
                          <a href="https://agra.org/" target="_blank">
                            <img className="img-fluid" src={agralogo}  style={{ width: '255px', height: '225px' }} alt="" />
                          </a>
                          
                          
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-2" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-4 col-md-6"></div>
                  </div>
                </div>
              </div>
              {/* Property List End */}
              {/* Call to Action Start */}
   
   

              <HomeFooter />
              {/* Back to Top */}
              <a
                href="#"
                className="btn btn-lg btn-primary btn-lg-square back-to-top"
              >
                <FontAwesomeIcon icon={faArrowUp} />
              </a>
            </div>
          </div>
       
      </>
    </>
  );
};

export default Home;
