import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Navbar, Nav, Dropdown, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../../store/store";
import useLogout from "../../../hooks/useLogout";
import MinistryLogo from "../../logo/MinistryLogo";

const NavBar = ({ toggleSidebar }) => {
  const { user, isApproved, isAdmin, isSuperAdmin } = useStore((state) => ({
    user: state.user,
    isApproved: state.isApproved,
    isAdmin: state.isAdmin,
    isSuperAdmin: state.isSuperAdmin,
  }));

  const navigate = useNavigate();
  const logout = useLogout();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
  };

  const handleLogout = () => {
    logout();
    setShowLogoutModal(false);
    // Redirect to home after logout
    navigate("/");
  };

  return (
    <Navbar
      bg="white"
      expand="sm"
      className="py-0 px-4 container-fluid nav-bar bg-transparent shadow"
    >
      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={toggleSidebar}
      >
        <FontAwesomeIcon icon={faBars} />
      </Navbar.Toggle>
      <Navbar.Brand className="my-1" onClick={() => setShowLogoutModal(true)}>
        <MinistryLogo />
      </Navbar.Brand>
      <Navbar.Collapse className="justify-content-end pe-2">
        <Nav className="ms-auto">
          <Nav.Item>
            <Dropdown>
              <Dropdown.Toggle variant="transparent" id="dropdown-basic">
                <span>
                  Hello, {user?.firstName}{" "}
                  {isSuperAdmin ? " (SuperAdmin)" : isAdmin ? " (Admin)" : ""}
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu alignRight>
                {isApproved && (
                  <Dropdown.Item as={Link} to="/profile">
                    Profile
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={handleLogoutClick}>
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>

      {/* Logout Confirmation Modal */}
      <Modal
        show={showLogoutModal}
        onHide={() => setShowLogoutModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Logout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Perfoming this action will log you out. Do you want to proceed?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowLogoutModal(false)}>
            No
          </Button>
          <Button variant="primary" onClick={handleLogout}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </Navbar>
  );
};

export default NavBar;
