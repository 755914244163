import React from "react";
import { Link, useNavigate } from "react-router-dom";
import useStore from "../store/store";
import useLogout from "../hooks/useLogout";

const UnauthorizedUser = () => {
  const { isAuthenticated, isAdmin, isApproved } = useStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    isAdmin: state.isAdmin,
    isApproved: state.isApproved,
  }));
  const logout = useLogout();
  const navigate = useNavigate();

  const handleLogout = async (event) => {
    event.preventDefault();
    await logout();
    navigate("/login");
  };

  return (
    <div className="container">
      <div className="row justify-content-center mt-5">
        <div className="col-md-6">
          <div className="card border-danger">
            <div className="card-header bg-danger text-white">
              Access Denied
            </div>
            <div className="card-body">
              {isAuthenticated ? (
                <>
                  {isAdmin ? (
                    <>
                      <p className="card-text">
                        As an admin, you do not have permission to access
                        stakeholder-specific routes.
                      </p>
                      <Link
                        to="/a/email-verified-stakeholders-approval"
                        className="btn btn-primary"
                      >
                        Go to Admin Dashboard
                      </Link>
                    </>
                  ) : isApproved ? (
                    <>
                      <p className="card-text">
                        You do not have permission to access this page. If you
                        believe this is an error, please verify your permissions
                        or contact support.
                      </p>
                      <Link
                        to="/u/data-requests"
                        className="btn btn-primary me-2"
                      >
                        My Requests
                      </Link>
                      <Link
                        to="/login"
                        className="btn btn-danger"
                        onClick={handleLogout}
                      >
                        Logout
                      </Link>
                    </>
                  ) : (
                    <>
                      <p className="card-text">
                        Your account status is pending approval. Please check
                        your status on the status page.
                      </p>
                      <Link to="/unverified" className="btn btn-primary">
                        Status Page
                      </Link>
                    </>
                  )}
                </>
              ) : (
                <>
                  <p className="card-text">
                    You may not have permission to access this page or your
                    session may have expired. Please log in to continue or
                    verify your permissions.
                  </p>
                  <Link to="/login" className="btn btn-danger">
                    Go to Login
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedUser;
