import React, { useState, useEffect, useCallback } from "react";
import { Card, Spinner } from "react-bootstrap";
import TenantsApprovalTable from "../../components/Admin/TenantsApprovalTable";
import useApiRequest from "../../hooks/useApiRequest";

const TenantsApproval = ({ endpoint }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const apiRequest = useApiRequest();

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await apiRequest({
        url: `/groups/${endpoint}-pending-accounts/members`,
      });

      if (response.ok) {
        const jsonData = await response.json();
        setData(jsonData);
      } else {
        console.error("Error fetching data:", response.statusText);
        setError(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [apiRequest, endpoint]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getTitle = () => {
    switch (endpoint) {
      case "email-verified":
        return "Verified Stakeholders Pending Approval";

      case "non-email-verified":
        return "Unverified Stakeholders Pending Approval";

      case "all":
        return "All Stakeholders Pending Approval";

      default:
        break;
    }
  };

  return (
    <>
      <Card className="shadow mb-4">
        <Card.Header className="d-flex flex-row align-items-center justify-content-between">
          <h6>{getTitle()}</h6>
        </Card.Header>
        <Card.Body>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : data && data.length > 0 ? (
            <TenantsApprovalTable users={data} loading={loading} />
          ) : error ? (
            <div className="text-center text-muted">
              <p>Error fetching data. Please try again later.</p>
            </div>
          ) : (
            <div className="text-center text-muted">
              <p>No data available to display.</p>
              <p>Please check back later or refresh the page after a while.</p>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default TenantsApproval;
