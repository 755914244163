import React from "react";
import {
  Alert,
  Modal,
  Button,
  Form,
  Col,
  Row as BsRow,
  Spinner,
} from "react-bootstrap";
import { FixedSizeList as List } from "react-window";

// Row component for react-window
const Row = ({ index, style, data }) => {
  const { fields, toggleMaskData } = data;
  const column = fields[index];

  return (
    <div style={style}>
      <BsRow className="align-items-center">
        <Col xs={2}>{index + 1}</Col>
        <Col xs={5}>
          {!column.alias || column.alias === "" ? column.title : column.alias}
        </Col>
        <Col xs={5}>
          <Form.Check
            type="checkbox"
            id={`maskCheckbox-${column.title}`}
            checked={column.masked}
            onChange={() => toggleMaskData(column.title)}
            label="Anonymize"
          />
        </Col>
      </BsRow>
    </div>
  );
};

function ApprovalAlert({ selectedRequest, canApprove, onApprove }) {
  const status = selectedRequest.status;
  let recommendation;
  if (status === "made") {
    recommendation = selectedRequest.makerRecommendationInitial.String;
  } else if (
    status === "maker-review" ||
    (status === "checked" && !selectedRequest.checkerRecommendationReview.Valid)
  ) {
    recommendation = selectedRequest.checkerRecommendationInitial.String;
  } else if (status === "checker-review") {
    recommendation = selectedRequest.makerRecommendationReview.String;
  } else {
    recommendation = selectedRequest.checkerRecommendationReview.String;
  }
  // Determine the message based on the status
  let message = "";
  if (status === "made" && recommendation === "reject") {
    message =
      "The Initiator recommended Reject. By you approving, it will go back to the initiator for review.";
  } else if (status === "maker-review" && recommendation === "reject") {
    message = "The Approver recommended Reject.";
  } else if (status === "checker-review" && recommendation === "reject") {
    message =
      "On Review, the initiator recommends Reject. By you approving, it will lead to an impasse.";
  }

  return (
    <>
      {message !== "" && (
        <Alert variant="warning" className="mt-2 mb-2">
          {message}
        </Alert>
      )}
      <Button
        variant={
          status === "checker-review" &&
          selectedRequest.makerRecommendationReview.String === "reject"
            ? "danger"
            : "primary"
        }
        onClick={() => onApprove("approve")}
        disabled={!canApprove}
        className="mb-4 mt-2"
      >
        {status === "checker-review" ? "Approve & Conclude Review" : "Approve"}
      </Button>
    </>
  );
}

function RejectionAlert({ selectedRequest, canReject, onApprove }) {
  const status = selectedRequest.status;
  let recommendation;
  if (status === "made") {
    recommendation = selectedRequest.makerRecommendationInitial.String;
  } else if (
    status === "maker-review" ||
    (status === "checked" && !selectedRequest.checkerRecommendationReview.Valid)
  ) {
    recommendation = selectedRequest.checkerRecommendationInitial.String;
  } else if (status === "checker-review") {
    recommendation = selectedRequest.makerRecommendationReview.String;
  } else {
    recommendation = selectedRequest.checkerRecommendationReview.String;
  }
  let message = "";
  if (status === "made" && recommendation === "approve") {
    message =
      "The Initiator recommended Approve. By you rejecting, it will go back to the initiation phase for review.";
  } else if (status === "maker-review" && recommendation === "approve") {
    message = "The Approver recommended Approve.";
  } else if (status === "checker-review" && recommendation === "approve") {
    message =
      "On Review, the initiator recommends Approve. By you rejecting, it will lead to an impasse.";
  }

  return (
    <>
      {message !== "" && (
        <Alert variant="warning" className="mt-2 mb-2">
          {message}
        </Alert>
      )}
      <Button
        variant="danger"
        onClick={() => onApprove("reject")}
        disabled={!canReject}
        className="mb-4 mt-2"
      >
        {status === "checker-review" ? "Reject & Conclude Review" : "Reject"}
      </Button>
    </>
  );
}

const DataRequestModal = ({
  showModal,
  handleClose,
  selectedRequest,
  onApprove,
  toggleMaskData,
  handleRejectReasonChange,
  feedbackInitial,
  handleFeedbackInitialChange,
  rejectReason,
  rejectionReasons,
  termsAccepted,
  handleCheckboxChange,
  handlerDSA,
  isSubmitting,
  isSuperAdmin,
}) => {
  const hasDSA = selectedRequest?.dsa?.trim() !== "";
  const requestStatus = selectedRequest?.status;
  const canApprove =
    hasDSA &&
    termsAccepted &&
    ((isSuperAdmin &&
      (requestStatus === "made" || requestStatus === "checker-review")) ||
      (!isSuperAdmin &&
        (requestStatus === "incoming" || requestStatus === "maker-review")));

  const canReject =
    rejectReason &&
    hasDSA &&
    termsAccepted &&
    feedbackInitial &&
    ((isSuperAdmin &&
      (requestStatus === "made" || requestStatus === "checker-review")) ||
      (!isSuperAdmin &&
        (requestStatus === "incoming" || requestStatus === "maker-review")));

  const handleRejectionReasonChangeInternal = (event) => {
    const reason = event.target.value;
    handleRejectReasonChange(reason);
  };

  return (
    <Modal show={showModal} onHide={handleClose} size="xl">
      <Modal.Header closeButton>
        <Modal.Title>
          {!isSuperAdmin && requestStatus === "incoming"
            ? "Primary Review Data Access"
            : isSuperAdmin && requestStatus === "made"
            ? "Secondary Review Data Access"
            : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ position: "relative" }}>
        {isSubmitting && (
          <div
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              zIndex: 1050,
              backgroundColor: "rgba(255, 255, 255, 0.75)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        )}

        <br />
        {!selectedRequest.fields || selectedRequest.fields.length === 0 ? (
          <p className="text-center">
            There is an unrestorable issue with this data request. Ask the
            stakeholder to make a new request.
          </p>
        ) : (
          <>
            {selectedRequest.makerRecommendationInitial.Valid && (
              <Alert
                className=""
                variant={
                  selectedRequest.makerRecommendationInitial.String ===
                  "approve"
                    ? "success"
                    : selectedRequest.makerRecommendationInitial.String ===
                      "reject"
                    ? "danger"
                    : "warning"
                }
              >
                <b>Initiator initial recommendation:</b>
                <p>{selectedRequest.makerRecommendationInitial.String}</p>
                {selectedRequest.makerFeedbackInitial.Valid && (
                  <>
                    <b>Initiator initial feedback:</b>
                    <p>{selectedRequest.makerFeedbackInitial.String}</p>
                  </>
                )}
              </Alert>
            )}
            {selectedRequest.checkerRecommendationInitial.Valid && (
              <Alert
                className="mt-1"
                variant={
                  selectedRequest.checkerRecommendationInitial.String ===
                  "approve"
                    ? "success"
                    : selectedRequest.checkerRecommendationInitial.String ===
                      "reject"
                    ? "danger"
                    : "warning"
                }
              >
                <b>Checker initial recommendation:</b>
                <p>{selectedRequest.checkerRecommendationInitial.String}</p>
                {selectedRequest.checkerFeedbackInitial.Valid && (
                  <>
                    <b>Checker initial feedback:</b>
                    <p>{selectedRequest.checkerFeedbackInitial.String}</p>
                  </>
                )}
              </Alert>
            )}
            {selectedRequest.makerRecommendationReview.Valid && (
              <Alert
                className="mt-1"
                variant={
                  selectedRequest.makerRecommendationReview.String === "approve"
                    ? "success"
                    : selectedRequest.makerRecommendationReview.String ===
                      "reject"
                    ? "danger"
                    : "warning"
                }
              >
                <b>Initiator review recommendation:</b>
                <p>{selectedRequest.makerRecommendationReview.String}</p>
                <b>Initiator review feedback:</b>
                <p>{selectedRequest.makerFeedbackReview.String}</p>
              </Alert>
            )}
            {selectedRequest.checkerRecommendationReview.Valid && (
              <Alert
                className="mt-1"
                variant={
                  selectedRequest.checkerRecommendationReview.String ===
                  "approve"
                    ? "success"
                    : selectedRequest.checkerRecommendationReview.String ===
                      "reject"
                    ? "danger"
                    : "warning"
                }
              >
                <b>Checker review recommendation:</b>
                <p>{selectedRequest.checkerRecommendationReview.String}</p>
                <b>Checker review feedback:</b>
                <p>{selectedRequest.checkerFeedbackReview.String}</p>
              </Alert>
            )}
            {selectedRequest?.dsa?.trim() !== "" && (
              <>
                <h6>Please read and review the Data Sharing Agreement below</h6>
                <Button
                  variant="info"
                  onClick={() => handlerDSA(selectedRequest.id)}
                  className="mt-2 mb-2"
                >
                  Review Data Sharing Agreement
                </Button>
                <Form.Group
                  controlId="termsAndConditionsCheckbox"
                  className="mb-3"
                >
                  <Form.Check
                    type="checkbox"
                    label={
                      <>
                        I have <b>read</b> and <b>reviewed</b> the Data Sharing
                        Agreement
                      </>
                    }
                    checked={termsAccepted}
                    onChange={handleCheckboxChange}
                  />
                </Form.Group>

                {!termsAccepted && (
                  <Alert variant="warning" className="mt-2 mb-2">
                    In order to approve or reject, you <b> must read</b> the
                    Data Sharing Agreement, and check the checkbox
                  </Alert>
                )}

                <br />
                <div className="virtualized-table">
                  <List
                    height={400}
                    itemCount={selectedRequest.fields.length}
                    itemSize={35}
                    width={"100%"}
                    itemData={{
                      fields: selectedRequest.fields,
                      toggleMaskData,
                    }}
                  >
                    {Row}
                  </List>
                </div>
              </>
            )}

            {hasDSA && (
              <>
                <h6 className="mt-2">If Approving</h6>
                {ApprovalAlert({ selectedRequest, canApprove, onApprove })}
                <Form.Group controlId="rejectionReasonSelect">
                  <h6>If Rejecting</h6>
                  <Form.Label>
                    please select a reason below:{" "}
                    <small>
                      <b>(Required)</b>
                    </small>
                  </Form.Label>
                  <Form.Select
                    value={rejectReason}
                    onChange={handleRejectionReasonChangeInternal}
                  >
                    <option value="">Select Rejection Reason</option>
                    {rejectionReasons.map((reason) => (
                      <option key={reason.ID} value={reason.ReasonID}>
                        {reason.Reason}
                      </option>
                    ))}
                    <option value="other">Other</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group className="mt-3">
                  <Form.Label>
                    Detailed Feedback:{" "}
                    <small>
                      <b>(Required)</b>
                    </small>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Provide detailed feedback on the rejection"
                    value={feedbackInitial}
                    onChange={(e) =>
                      handleFeedbackInitialChange(e.target.value)
                    }
                  />
                </Form.Group>
              </>
            )}
            {RejectionAlert({ selectedRequest, canReject, onApprove })}
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default DataRequestModal;
