import React from "react";
import useStore from "../../../store/store";
import SidebarUser from "./SidebarUser";
import NavAdmin from "./NavAdmin";
import NavUnverified from "./NavUnverified";

const Sidebar = () => {
  const { isAdmin, isApproved } = useStore((state) => ({
    isAdmin: state.isAdmin,
    isApproved: state.isApproved,
  }));

  // If admin, show admin Nav, if unverified, show unverified Nav, else show user Nav
  if (isAdmin) {
    return <NavAdmin />;
  }
  if (isApproved) {
    return <SidebarUser />;
  }
  return <NavUnverified />;
};

export default Sidebar;
