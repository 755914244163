import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../store/store";

const useLogout = (apiRequest) => {
  const resetStore = useStore((state) => state.reset);
  const navigate = useNavigate();

  const logout = useCallback(
    async (reason = "success-logout", nextRoute = "/login") => {
      try {
        // Reset the store to its initial state
        resetStore();

        if (apiRequest) {
          // Make the logout API call
          await apiRequest({ url: "/auth/logout", method: "POST" });
        }

        // Navigate to the login page with message and next route
        navigate(nextRoute, { state: { reason } });
      } catch (error) {
        console.error("Logout failed:", error);
      }
    },
    [resetStore, navigate, apiRequest]
  );

  return logout;
};

export default useLogout;
