import React, { useState } from "react";
import { Button, Form, ToastContainer } from "react-bootstrap";
import toast from "react-hot-toast";
import useApiRequest from "../../hooks/useApiRequest";
import useLogout from "../../hooks/useLogout";
import { useNavigate } from "react-router-dom";

const ProfileSettings = ({ userData }) => {
  const { firstName, lastName, email: initialEmail } = userData || {};
  const phoneNumber = userData?.attributes?.phoneNumber || "N/A";
  const [email, setEmail] = useState(initialEmail || "");
  const [newPassword, setNewPassword] = useState("");
  const apiRequest = useApiRequest();
  const logout = useLogout();
  const navigate = useNavigate();
  
  const handleLogout = () => {
    setTimeout(() => {
        logout();
        navigate("/"); // Redirect to home after logout
    }, 1500); // Delay timer 
};


  const handlePasswordChange = async () => {
    try {
      const requestBody = { email };
      const response = await apiRequest({
        url: "/auth/reset-password",
        method: "POST",
        body: { email },
      });

      if (response.ok) {
        toast.success("Password reset link has been sent to your email");
        handleLogout();
      } else {
        toast.error("Password update failed");
      }
    } catch (error) {
      console.error("An error occurred during password update:", error);
      toast.error("An error occurred during password update.");
    }
  };

  return (
    <Form className="p-4 rounded bg-light">
      <h4 className="text-center mb-4">User Profile</h4>
      <Form.Group className="mb-3">
        <Form.Label>First Name:</Form.Label>
        <span className="ps-2">{firstName}</span>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Last Name:</Form.Label>
        <span className="ps-2">{lastName}</span>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Phone Number:</Form.Label>
        <span className="ps-2">{phoneNumber}</span>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Reset Password: A reset link will be sent to the email provided below </Form.Label>
        <Form.Control
          type="email"
          placeholder="Enter Email to reset password"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>

      <div className="text-center">
        <Button variant="success" onClick={handlePasswordChange}>
          Change Password
        </Button>
      </div>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
    </Form>
  );
};

export default ProfileSettings;