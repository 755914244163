import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Alert,
  Form,
  Button,
  Spinner,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import useStore from "../store/store";
import useApiRequest from "../hooks/useApiRequest";
import LoadingOverlay from "../components/layout/components/LoadingOverlay";
import MinistryLogo from "../components/logo/MinistryLogo";

const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [emailNotVerified, setEmailNotVerified] = useState(false);
  const [error, setError] = useState("");
  const [logoutReason, setLogoutReason] = useState("");
  const [alertType, setAlertType] = useState("info");
  const {
    setAuthData,
    setUser,
    setIsAuthenticated,
    setAdmin,
    setSuperAdmin,
    setIsApproved,
  } = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const apiRequest = useApiRequest();
  const reason = location.state?.reason; // Get the logout message
  const nextRoute = location.state?.nextRoute; // Get the next route

  useEffect(() => {
    // Display the logout message if available
    if (reason && reason.length > 0) {
      switch (reason) {
        case "success-logout":
          setLogoutReason("You have successfully logged out.");
          break;
        case "error-forbidden":
          setLogoutReason(
            "Access denied. You might not have the necessary permissions or your session might have been invalidated. Please log in again to continue."
          );
          break;
        case "error-expired-token":
          setLogoutReason(
            "Your session has expired due to inactivity. Please log in again to continue where you left off."
          );
          break;
        case "info-logged-out":
          setLogoutReason(
            "You have been logged out. Please log in again to continue where you left off."
          );
          break;
        default:
          setLogoutReason(
            "An unknown error occurred. Please try logging in again."
          );
          break;
      }
    }
  }, [reason]);

  useEffect(() => {
    if (reason && reason.length > 0) {
      const reasonPrefix = reason.split("-")[0]; // Extract prefix (e.g., "success", "error")

      switch (reasonPrefix) {
        case "success":
          setAlertType("primary");
          break;
        case "error":
          setAlertType("warning");
          break;
        default:
          setAlertType("info");
          break;
      }
    }
  }, [logoutReason, reason]);

  const onSubmit = async (data) => {
    if (!data.email || !data.password) {
      setError("Please fill in all fields");
      return;
    }

    setLoading(true);
    try {
      const response = await apiRequest({
        url: "/auth/login",
        method: "POST",
        body: { ...data },
        needsToken: false,
      });

      if (response.ok) {
        const data = await response.json();
        setAuthData(data);
        setUser(data.profile);
        setIsAuthenticated(true);
        navigate(nextRoute || "/");
        const isAdmin = data.profile.groups.some(
          (group) => group.name === "admin"
        );
        const isSuperAdmin = data.profile.groups.some(
          (group) => group.name === "super-admin"
        );
        const isApproved = data.profile.groups.some(
          (group) => group.name === "approved-tenants"
        );
        setAdmin(isAdmin || isSuperAdmin);
        setSuperAdmin(isSuperAdmin);
        setIsApproved(isApproved);

        if (data.profile && data.profile.groups) {
          // Navigate based on user role
          console.log("Routing...");
          if (!isApproved) {
            navigate("/unverified");
          } else {
            const redirectRoute =
              nextRoute ||
              (isAdmin || isSuperAdmin
                ? "/a/email-verified-stakeholders-approval"
                : "/u/new-data-request");
            navigate(redirectRoute);
          }
        } else {
          console.error("Invalid data from the API.");
          // Navigate to the login page or handle accordingly
          navigate("/login");
        }
      } else {
        const errorBody = await response.json();
        console.log(errorBody);
        let apiError =
          errorBody.error_description ??
          "Failed to Login. Please Check your credentials and try again";
        if (errorBody.error_description === "Account disabled") {
          apiError = "Your account has been rejected/disabled.";
        } else if (
          errorBody.error_description === "Account is not fully set up"
        ) {
          apiError =
            "Your email is not yet verified, please verify to proceed.";
          setEmailNotVerified(true);
        }
        setError(apiError);
        return;
      }
    } catch (error) {
      setError("An unexpected error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoadingOverlay loading={loading} />}
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col md={6} lg={4}>
            <div className="w-100 d-flex justify-content-center mb-4">
              <MinistryLogo onClick={() => navigate("/")} />
            </div>
            <h6 className="text-center my-4">ASDG Login</h6>
            {logoutReason && <Alert variant={alertType}>{logoutReason}</Alert>}
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  {...register("email", { required: true })}
                  isInvalid={errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  Email is required.
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  placeholder="Password"
                  {...register("password", { required: true })}
                  isInvalid={errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  Password is required.
                </Form.Control.Feedback>
              </Form.Group>
              {error && <Alert variant="danger">{error}</Alert>}
              <Button
                variant="primary"
                type="submit"
                className="w-100 mt-2"
                disabled={loading}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="lg"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Login"
                )}
              </Button>
            </Form>
            <div className="mt-3 text-center">
              <Link to="/forgot-password">Forgot your password?</Link>
            </div>
            <div className="mt-3 text-center">Don't have an account?</div>

            <a href="/signup" className="btn btn-success w-100 mt-2">
              Create Account
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
