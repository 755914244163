import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import "chart.js/auto";
import {
  Badge,
  Button,
  Card,
  Pagination,
  Spinner,
  Table,
} from "react-bootstrap";
import "reactjs-popup/dist/index.css";
import CopyButton from "../../components/CopyButton";
import useApiRequest from "../../hooks/useApiRequest";
import { getCountyName } from "../../utils/getCountiesFromCodes";

const UserDataRequests = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const apiRequest = useApiRequest();

  const handleGetDataClick = (requestId) => {
    navigate(`/u/data-requests/${requestId}`); // Navigate to the new path
  };

  // Define the number of items per page
  const itemsPerPage = 10; // Adjust this based on your preference

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await apiRequest({
          url: `/data-requests/user-requests?page=${currentPage}&limit=${itemsPerPage}`,
          method: "GET",
        });

        if (response.ok) {
          const jsonData = await response.json();
          if (jsonData) {
            setData(jsonData.dataRequests);
            setTotalPages(jsonData.totalPages);
          } else {
            console.error("Error: Received null or undefined response data");
          }
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiRequest, currentPage, itemsPerPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const renderPagination = () => {
    let items = [];
    let active = currentPage;
    let itemsCount = totalPages;

    if (itemsCount <= 10) {
      for (let number = 1; number <= itemsCount; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }
    } else {
      items.push(
        <Pagination.Item
          key="1"
          active={1 === active}
          onClick={() => handlePageChange(1)}
        >
          1
        </Pagination.Item>
      );
      items.push(
        <Pagination.Item
          key="2"
          active={2 === active}
          onClick={() => handlePageChange(2)}
        >
          2
        </Pagination.Item>
      );

      // Dynamically add the current, previous, and next two pages if not near the start or end
      let startPage = active > 4 ? active - 2 : 3;
      let endPage = active + 2 < itemsCount - 1 ? active + 2 : itemsCount - 2;

      if (startPage > 3) {
        items.push(<Pagination.Ellipsis key="startEllipsis" />);
      }

      for (let number = startPage; number <= endPage; number++) {
        items.push(
          <Pagination.Item
            key={number}
            active={number === active}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </Pagination.Item>
        );
      }

      if (endPage < itemsCount - 2) {
        items.push(<Pagination.Ellipsis key="endEllipsis" />);
      }

      items.push(
        <Pagination.Item
          key={itemsCount - 1}
          active={itemsCount - 1 === active}
          onClick={() => handlePageChange(itemsCount - 1)}
        >
          {itemsCount - 1}
        </Pagination.Item>
      );
      items.push(
        <Pagination.Item
          key={itemsCount}
          active={itemsCount === active}
          onClick={() => handlePageChange(itemsCount)}
        >
          {itemsCount}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <Card className="mb-4">
      <Card.Header className="py-3">
        <h6 className="m-0 font-weight-bold">My Data Requests</h6>
      </Card.Header>
      <Card.Body>
        <table class="table table-bordered">
          <div>
            {loading ? (
              <div className="text-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : (
              <>
                <h6>Request Details</h6>
                <Table hover bordered responsive>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Category</th>
                      <th>Counties</th>
                      <th>Date</th>
                      <th>Topics</th>
                      <th>Status</th>
                      <th>Data Access</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((request) => (
                      <tr key={request.id}>
                        <td width={200}>
                          <span className="small">{request.id}</span>
                          <br />
                          <CopyButton text={request.id} />
                        </td>
                        <td>
                          <span className="small">{request.registry}</span>{" "}
                        </td>

                        <td>
                          <span className="small">
                            {request.county &&
                              request.county.length > 0 &&
                              getCountyName(request.county.join(", "))}
                          </span>
                        </td>

                        <td>
                          <div>
                            <Badge className="" bg="info">
                              Request Date
                            </Badge>
                            <br />
                            <small className="small">
                              {(() => {
                                let date = new Date(request.serverTimestamp);
                                let dateString = date.toLocaleDateString();
                                let timeString = date.toLocaleTimeString([], {
                                  hour: "numeric",
                                  minute: "2-digit",
                                });
                                return `${dateString}, ${timeString}`;
                              })()}
                            </small>
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            {request.datetimeOfResponse !== null && (
                              <div>
                                <Badge
                                  className=""
                                  bg={`${
                                    request.status === "made"
                                      ? "warning"
                                      : request.status === "checked"
                                      ? "success"
                                      : request.status === "rejected"
                                      ? "warning"
                                      : "info"
                                  }`}
                                >
                                  Response Date
                                </Badge>
                                <br />
                                <small>
                                  {(() => {
                                    let date = new Date(
                                      request.datetimeOfResponse
                                    );
                                    let dateString = date.toLocaleDateString();
                                    let timeString = date.toLocaleTimeString(
                                      [],
                                      { hour: "numeric", minute: "2-digit" }
                                    );
                                    return `${dateString}, ${timeString}`;
                                  })()}
                                </small>
                              </div>
                            )}
                          </div>
                          {request.adminMaker.Valid && (
                            <>
                              <Badge className="" bg="warning">
                                Verifier:
                              </Badge>
                              <br />
                              <small>{request.adminMaker.String}</small>
                            </>
                          )}
                          <br />
                          <div style={{ marginTop: "10px" }}>
                            {request.datetimeOfResponse !== null && (
                              <div>
                                <Badge
                                  className=""
                                  bg={`${
                                    request.status === "made"
                                      ? "warning"
                                      : request.status === "checked"
                                      ? "success"
                                      : request.status === "rejected"
                                      ? "warning"
                                      : "info"
                                  }`}
                                >
                                  Response Date
                                </Badge>
                                <br />
                                <small>
                                  {(() => {
                                    let date = new Date(
                                      request.datetimeOfResponse
                                    );
                                    let dateString = date.toLocaleDateString();
                                    let timeString = date.toLocaleTimeString(
                                      [],
                                      { hour: "numeric", minute: "2-digit" }
                                    );
                                    return `${dateString}, ${timeString}`;
                                  })()}
                                </small>
                              </div>
                            )}
                          </div>
                          {request.adminChecker.Valid && (
                            <>
                              <Badge className="" bg="warning">
                                Approver:
                              </Badge>
                              <br />
                              <small>{request.adminChecker.String}</small>
                              <div style={{ marginTop: "10px" }}>
                            {request.datetimeOfResponse !== null && (
                              <div>
                                <Badge
                                  className=""
                                  bg={`${
                                    request.status === "made"
                                      ? "warning"
                                      : request.status === "checked"
                                      ? "success"
                                      : request.status === "rejected"
                                      ? "warning"
                                      : "info"
                                  }`}
                                >
                                  Response Date
                                </Badge>
                                <br />
                                <small>
                                  {(() => {
                                    let date = new Date(
                                      request.datetimeOfResponse
                                    );
                                    let dateString = date.toLocaleDateString();
                                    let timeString = date.toLocaleTimeString(
                                      [],
                                      { hour: "numeric", minute: "2-digit" }
                                    );
                                    return `${dateString}, ${timeString}`;
                                  })()}
                                </small>
                              </div>
                            )}
                          </div>   
                            </>
                          )}
                        </td>

                        <td>
                          {request.subjects.map((subject, index) => (
                            <div key={index} className="mb-2">
                              <strong>
                                <div key={index} className="mb-2">
                                  <Badge bg="success" className="">
                                    {subject.title}
                                  </Badge>
                                </div>
                              </strong>
                              <ul>
                                {subject.topics.map((topic, topicIndex) => (
                                  <li key={topicIndex}>
                                    <span className="small">{topic}</span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </td>

                        <td>
                          <Badge
                            bg={`${
                              request.status === "checked"
                                ? "primary"
                                : request.status === "made"
                                ? "warning"
                                : request.status === "rejected"
                                ? "danger"
                                : "info"
                            }`}
                          >
                            {capitalizeFirstLetter(
                              request.status === "incoming"
                                ? "Pending verification"
                                : request.status === "made" ||
                                  request.status === "maker-review" ||
                                  request.status === "checker-review"
                                ? "Pending approval"
                                : request.status === "checked"
                                ? "Approved"
                                : request.status === "rejected"
                                ? "Rejected"
                                : request.status === "impasse"
                                ? "Impasse"
                                : request.status
                            )}
                          </Badge>

                          {request.statusReason?.trim() !== "" && (
                            <>
                              <br />
                              <small className="font-weight-light">
                                {request.statusReason}
                              </small>
                            </>
                          )}
                        </td>

                        <td>
                          {request.status === "checked" ? (
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={() => handleGetDataClick(request.id)}
                            >
                              <strong> Access Data</strong>
                            </Button>
                          ) : (
                            <Button variant="danger" size="sm" disabled>
                              Inactive
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div>
                  <Pagination>
                    <Pagination.First
                      onClick={() => handlePageChange(1)}
                      disabled={currentPage === 1}
                    />
                    <Pagination.Prev
                      onClick={() => handlePageChange(currentPage - 1)}
                      disabled={currentPage === 1}
                    />

                    {renderPagination()}

                    <Pagination.Next
                      onClick={() => handlePageChange(currentPage + 1)}
                      disabled={currentPage === totalPages}
                    />
                    <Pagination.Last
                      onClick={() => handlePageChange(totalPages)}
                      disabled={currentPage === totalPages}
                    />
                  </Pagination>
                </div>
              </>
            )}
          </div>
        </table>
      </Card.Body>
    </Card>
  );
};

export default UserDataRequests;
