import React from "react";

const DataRequestReason = ({ value, onChange }) => {
  return (
    <div className="mb-3">
      <h6>
        Justify Reason For Data Request: (<small>Required</small>)
      </h6>
      <textarea
        id="reason"
        name="reason"
        rows={4}
        className="form-control"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default DataRequestReason;
