import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import TermsPage from "../pages/TermsPage";

const TermsAndConditionsModal = ({ show, onHide, onChanged }) => {
  const [termsChecked, setTermsChecked] = useState(false);

  // Inline style for the scrollable area
  const scrollableModalBodyStyle = {
    maxHeight: "70vh", // Set the maximum height of the modal body
    overflowY: "scroll", // Add vertical scroll
  };

  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setTermsChecked(checked);
    onChanged(checked); // Update the parent state whenever the checkbox changes
  };

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Terms and Conditions</Modal.Title>
      </Modal.Header>
      <Modal.Body style={scrollableModalBodyStyle}>
        <TermsPage />
        <Form.Group className="mb-3" controlId="termsAndConditionsCheckbox">
          <Form.Check
            type="checkbox"
            className="mt-4"
            label={
              <>
                I have <b>read</b> and <b>accepted</b> the terms and conditions
              </>
            }
            checked={termsChecked}
            onChange={handleCheckboxChange}
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant={termsChecked ? "primary" : "secondary"}
          onClick={onHide}
        >
          {termsChecked ? "Save" : "Close"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default TermsAndConditionsModal;
