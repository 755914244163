import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";

const FaqQuestionItem = ({ questionNumber, question, answer }) => (
  <Accordion.Item eventKey={questionNumber}>
    <Accordion.Header>
      {questionNumber}. {question}
    </Accordion.Header>
    <Accordion.Body>{answer}</Accordion.Body>
  </Accordion.Item>
);

export default FaqQuestionItem;
