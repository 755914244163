import LoadingComponent from "./LoadingComponent";

// Adjustments to LoadingComponent for overlay use
const LoadingOverlay = ({ loading }) => {
  if (!loading) return null;

  return (
    <div
      style={{
        position: "fixed", // Overlay the entire screen
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.5)", // Semi-transparent background
        zIndex: 1050, // High z-index to be on top of other content
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingComponent />
    </div>
  );
};

export default LoadingOverlay;
