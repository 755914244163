import React from "react";
import { OverlayTrigger, Tooltip, Form, Spinner } from "react-bootstrap";

const DataSubjectSelect = ({
  subjects,
  selectedSubject,
  onChange,
  isLoadingSubjects,
  isLoadingTopics,
}) => {
  const tooltip = (
    <Tooltip id="tooltip-select-data-subject">
      This is the next level grouping of data below a category into subjects
      e.g., in the Actor Registry, there will be information about farmers,
      extension workers etc.
    </Tooltip>
  );

  return (
    <div>
      <OverlayTrigger placement="top" overlay={tooltip}>
        <h6>
          Select a Data Subject: (<small>Required</small>)
        </h6>
      </OverlayTrigger>
      {isLoadingSubjects && (
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      )}
      {subjects.length === 0 && !isLoadingSubjects && (
        <p className="text-muted">
          <em>Please select a Category first.</em>
        </p>
      )}
      {subjects.length > 0 &&
        subjects.map((subject, index) => (
          <Form.Check
            type="radio"
            id={`radio-subject-${index}`}
            name="dataSubjectRadios"
            label={subject}
            value={subject}
            checked={selectedSubject === subject}
            onChange={(e) => onChange(e.target.value)}
            disabled={isLoadingTopics}
            key={index}
          />
        ))}
    </div>
  );
};

export default DataSubjectSelect;
