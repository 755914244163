import React from "react";

const DSAFilePicker = () => {
  return (
    <div className="mb-3">
      <label htmlFor="dsa">
        Attach Data Sharing Agreement File: (
        <small>Must be a PDF file, Max size 10MB</small>) (
        <b>
          <small>Required</small>
        </b>
        )
      </label>
      <input
        type="file"
        id="dsa"
        name="dsa"
        className="form-control"
        accept=".pdf"
      />
    </div>
  );
};

export default DSAFilePicker;
