export const apiRequest = async (
  url,
  accessToken,
  method = "GET",
  body = null,
  additionalHeaders = {}
) => {
  try {
    const headers = {
      Authorization: `Bearer ${accessToken}`,
      ...additionalHeaders,
    };

    // Do not explicitly set 'Content-Type' to 'application/json' if the body is FormData.
    // The browser will set the correct 'Content-Type' for FormData.
    if (!(body instanceof FormData)) {
      headers["Content-Type"] = "application/json";
    }

    const config = {
      method: method,
      headers: headers,
      body:
        method !== "GET" && body
          ? body instanceof FormData
            ? body
            : JSON.stringify(body)
          : null,
    };

    const response = await fetch(url, config);

    return response;
  } catch (error) {
    console.error("Fetch error:", error);
    throw error;
  }
};
