import React from "react";
import { Alert } from "react-bootstrap";

const DSARadioSelect = ({ hasSignedDSA, onChange }) => {
  return (
    <div className="mb-3">
      <label className="form-label">
        <h6>
          Do you have an approved Data Sharing Agreement? (
          <small>Required</small>)
        </h6>
      </label>
      <div className="d-flex flex-column">
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            id="radioYes"
            name="radioDSA"
            value={true}
            checked={hasSignedDSA}
            onChange={() => onChange(true)}
          />
          <label className="form-check-label" htmlFor="radioYes">
            Yes
          </label>
        </div>
        <div className="form-check">
          <input
            className="form-check-input"
            type="radio"
            id="radioNo"
            name="radioDSA"
            value={false}
            checked={!hasSignedDSA}
            onChange={() => onChange(false)}
          />
          <label className="form-check-label" htmlFor="radioNo">
            No
          </label>
        </div>
      </div>
      {!hasSignedDSA && (
        <Alert variant="danger">
          You need an approved Data Sharing Agreement to request for data.
          <br />
          Please contact support below for further information
        </Alert>
      )}
    </div>
  );
};

export default DSARadioSelect;
