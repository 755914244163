import React, { useState } from "react";
import HomeNav from "../../components/Home/HomeNavMenu";

import HomeFooter from "../../components/Home/HomeFooter";

const About = () => {
  return (
    <>
      <>
        {/* Navbar Start */}

        <HomeNav />

        {/* Navbar Ends */}

        {/* Footer Starts */}

        <div className="container-xxl bg-white p-0">
          <div className="container-fluid" style={{ marginTop: "40px" }}>
            <div className="container">
              <h2 className="text-center asdg-title"></h2>
            </div>

            <div className="container mt-5">
              <p>
                The Agriculture Sector Data Gateway (ASDG) is a platform
                developed by the Ministry of Agriculture and Livestock
                Development for seamless data exchange amongst actors within the
                agriculture sector.
              </p>
            </div>
          </div>

          <HomeFooter />
        </div>

        {/* Footer Ends */}
      </>
    </>
  );
};

export default About;
