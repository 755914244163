import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Select from "react-select";

const CategorySelect = ({
  options,
  selectedOption,
  onChange,
  isLoadingSubjects,
  isLoadingTopics,
}) => {
  const tooltip = (
    <Tooltip id="tooltip">
      This is the top-level grouping of data around a category e.g. Actor
      Registry stores informantion about actors.
    </Tooltip>
  );

  return (
    <div className="mb-3">
      <OverlayTrigger placement="top" overlay={tooltip}>
        <h6>
          Select a Category: (<small>Required</small>)
        </h6>
      </OverlayTrigger>
      <Select
        id="dataRegistry"
        name="dataRegistry"
        options={options}
        value={selectedOption}
        onChange={onChange}
        isDisabled={isLoadingSubjects || isLoadingTopics}
        isSearchable
        placeholder="Start typing to search..."
      />
    </div>
  );
};

export default CategorySelect;
