import React from "react";
import { NavLink, useMatch, useResolvedPath } from "react-router-dom";

const CustomNavLink = ({ to, children }) => {
  let resolved = useResolvedPath(to);
  let match = useMatch({ path: resolved.pathname, end: true });

  return (
    <li className={match ? "nav-item active" : "nav-item"}>
      <NavLink to={to} className="nav-link">
        {children}
      </NavLink>
    </li>
  );
};

export default CustomNavLink;
