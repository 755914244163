// LoadingComponent.js
import React from "react";
import { PuffLoader } from "react-spinners";

const LoadingComponent = ({ loading, size = 100, color = "#347925" }) => {
  return (
    <div
      className="loading-component"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <PuffLoader color={color} loading={loading} size={size} />
    </div>
  );
};

export default LoadingComponent;
