import React from "react";
import { Table, Button, Badge, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import CopyButton from "../CopyButton";
import { getCountyName } from "../../utils/getCountiesFromCodes";

const AdminDataRequestsTable = ({
  dataRequests,
  handlerReview,
  loading,
  handlerDSA,
  isSuperAdmin,
  endpoint,
}) => {
  if (loading) {
    return (
      <div className="text-center">
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Loading...</span>
        </Spinner>
      </div>
    );
  }

  return (
    <Table striped bordered hover size="sm">
      <thead>
        <tr>
          <th>Request ID</th>
          <th>Applicant</th>
          <th>Organization</th>
          <th>Category Requested</th>
          <th>Counties</th>
          <th>Dates</th>
          <th>Data Topics</th>
          <th>Reason</th>
          <th>Data Agreement</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {dataRequests.map((request) => {
          const canReview = isSuperAdmin
            ? request.status === "made" || request.status === "checker-review"
            : request.status === "incoming" ||
            request.status === "maker-review";
          const buttonVariant = canReview ? "success" : "secondary";

          const buttonText = canReview
            ? "Review"
            : endpoint === "approved-checked"
              ? "Approved"
              : endpoint === "rejected-checked"
                ? "Rejected"
                : "Cannot Review";
          return (
            <tr key={request.id}>
              <td>
                <small>{request.id}</small>
                <br />
                <CopyButton text={request.id} />
              </td>
              <td>
                <small>
                  {request.firstName} {request.lastName}
                </small>
              </td>
              <td>
                <small>{request.organization}</small>
              </td>
              <td>
                <small>{request.registry}</small>
              </td>
              <td>
                <span className="small">
                  {request.county &&
                    request.county.length > 0 &&
                    getCountyName(request.county.join(", "))}
                </span>
              </td>
              <td>
                <div>
                  <Badge className="" bg="info">
                    Request Date
                  </Badge>
                  <br />
                  <small className="small">
                    {(() => {
                      let date = new Date(request.serverTimestamp);
                      let dateString = date.toLocaleDateString();
                      let timeString = date.toLocaleTimeString([], {
                        hour: "numeric",
                        minute: "2-digit",
                      });
                      return `${dateString}, ${timeString}`;
                    })()}
                  </small>
                </div>
                <div style={{ marginTop: "10px" }}>
                  {request.datetimeOfResponse !== null && (
                    <div>
                      <Badge
                        className=""
                        bg={`${request.status === "made"
                          ? "warning"
                          : request.status === "checked"
                            ? "success"
                            : request.status === "rejected"
                              ? "danger"
                              : "info"
                          }`}
                      >
                        Response Date
                      </Badge>
                      <br />
                      <small className="small">
                        {(() => {
                          let date = new Date(request.datetimeOfResponse);
                          let dateString = date.toLocaleDateString();
                          let timeString = date.toLocaleTimeString([], {
                            hour: "numeric",
                            minute: "2-digit",
                          });
                          return `${dateString}, ${timeString}`;
                        })()}
                      </small>
                    </div>
                  )}
                </div>
                {request.adminMaker.Valid && (
                  <>
                    <Badge
                      className=""
                      bg={
                        request.makerRecommendationInitial.String === "approve"
                          ? "success"
                          : request.makerRecommendationInitial.String ===
                            "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Initiated by:
                    </Badge>
                    <br />
                    <small>{request.adminMaker.String}</small>
                    <div>
                      <Badge className="" bg="info">
                        Request Date
                      </Badge>
                      <br />
                      <small className="small">
                        {(() => {
                          let date = new Date(request.serverTimestamp);
                          let dateString = date.toLocaleDateString();
                          let timeString = date.toLocaleTimeString([], {
                            hour: "numeric",
                            minute: "2-digit",
                          });
                          return `${dateString}, ${timeString}`;
                        })()}
                      </small>
                    </div>
                    <div style={{ marginTop: "10px" }}>
                      {request.datetimeOfResponse !== null && (
                        <div>
                          <Badge
                            className=""
                            bg={`${request.status === "made"
                                ? "warning"
                                : request.status === "checked"
                                  ? "success"
                                  : request.status === "rejected"
                                    ? "warning"
                                    : "info"
                              }`}
                          >
                            Response Date
                          </Badge>
                          <br />
                          <small>
                            {(() => {
                              let date = new Date(
                                request.datetimeOfResponse
                              );
                              let dateString = date.toLocaleDateString();
                              let timeString = date.toLocaleTimeString(
                                [],
                                { hour: "numeric", minute: "2-digit" }
                              );
                              return `${dateString}, ${timeString}`;
                            })()}
                          </small>
                        </div>
                      )}
                    </div>


                  </>
                )}
                {request.makerRecommendationInitial.Valid && (
                  <>
                    <Badge
                      className=""
                      bg={
                        request.makerRecommendationInitial.String === "approve"
                          ? "success"
                          : request.makerRecommendationInitial.String ===
                            "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Initiator recommendation:
                    </Badge>
                    <br />
                    <small>{request.makerRecommendationInitial.String}</small>

                  </>
                )}
                {request.adminChecker.Valid && (
                  <>
                    <br />
                    <Badge
                      className=""
                      bg={
                        request.checkerRecommendationInitial.String ===
                          "approve"
                          ? "success"
                          : request.checkerRecommendationInitial.String ===
                            "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Approved by:
                    </Badge>
                    <br />
                    <small>{request.adminChecker.String}</small>
                  </>
                )}
                {request.checkerRecommendationInitial.Valid && (
                  <>
                    <Badge
                      className=""
                      bg={
                        request.checkerRecommendationInitial.String ===
                          "approve"
                          ? "success"
                          : request.checkerRecommendationInitial.String ===
                            "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Approver recommendation:
                    </Badge>
                    <br />
                    <small>{request.checkerRecommendationInitial.String}</small>
                    <br />
                  </>
                )}

                {request.makerRecommendationReview.Valid && (
                  <>
                    <Badge
                      className=""
                      bg={
                        request.makerRecommendationReview.String === "approve"
                          ? "success"
                          : request.makerRecommendationReview.String ===
                            "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Initiator review recommendation:
                    </Badge>
                    <br />
                    <small>{request.makerRecommendationReview.String}</small>
                    <br />
                  </>
                )}

                {request.checkerRecommendationReview.Valid && (
                  <>
                    <Badge
                      className=""
                      bg={
                        request.checkerRecommendationReview.String === "approve"
                          ? "success"
                          : request.checkerRecommendationReview.String ===
                            "reject"
                            ? "danger"
                            : "warning"
                      }
                    >
                      Approver review recommendation:
                    </Badge>
                    <br />
                    <small>{request.checkerRecommendationReview.String}</small>
                    <br />
                  </>
                )}
              </td>
              <td>
                {request.subjects.map((subject, index) => (
                  <div key={index} className="mb-2">
                    <strong>
                      <div key={index} className="mb-2">
                        <Badge bg="success" className="">
                          {subject.title}
                        </Badge>
                      </div>
                    </strong>
                    <ul>
                      {subject.topics.map((topic, topicIndex) => (
                        <li key={topicIndex}>
                          <span className="small">{topic}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </td>
              <td>{request.reason}</td>
              <td>
                <td>
                  {request.dsa && request.dsa !== "" ? (
                    <>
                      <Badge bg="success">Attached</Badge>
                      <Button
                        style={{
                          background: "none",
                          borderColor: "transparent",
                          color: "#347925",
                          textDecoration: "underline",
                        }}
                        onClick={() => handlerDSA(request.id)}
                      >
                        View
                        <FontAwesomeIcon
                          className="ms-2"
                          icon={faExternalLinkAlt}
                          size="xs"
                        />
                      </Button>
                    </>
                  ) : (
                    <Badge bg="danger">Missing</Badge>
                  )}
                </td>
              </td>
              <td>
                <Button
                  variant={buttonVariant}
                  onClick={() => handlerReview(request)}
                  disabled={!canReview}
                >
                  {buttonText}
                </Button>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default AdminDataRequestsTable;
