import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Card, Form, Table, Spinner, Button, Alert } from "react-bootstrap";
import "chart.js/auto";
import "reactjs-popup/dist/index.css";
import { registerForApiAccess } from "../../utils/apiServices";
import useApiRequest from "../../hooks/useApiRequest";
import useFileDownloadRequest from "../../hooks/useFileDownloadRequest";
import TermsAndConditionsModal from "../../components/TermsAndConditionsModal";

const GetData = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [flashMessage, setFlashMessage] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const apiRequest = useApiRequest();
  const fileDownloadRequest = useFileDownloadRequest();

  const { requestId } = useParams();

  const navigate = useNavigate();

  const handleTermsAccept = (accepted) => {
    setTermsAccepted(accepted);
  };

  const handleDownload = async (fileType) => {
    setLoading(true);
    const endpoint = `/data-requests/requested-data-download?requestId=${requestId}&format=${fileType}`;

    try {
      const blob = await fileDownloadRequest(endpoint, fileType);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `downloaded_data.${fileType}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const apiUrl = `/data-requests/requested-data-sample?requestId=${requestId}`;
        const response = await apiRequest({
          url: apiUrl,
          shouldCache: true,
        });

        if (response.ok) {
          const jsonData = await response.json();
          setData(jsonData);
        } else {
          console.error("Error fetching data:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching request data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [apiRequest, requestId]);

  // Function to dynamically generate table headers based on data keys
  const generateTableHeaders = (data) => {
    if (!data || data.length === 0) return [];
    const headers = Object.keys(data[0]);
    return headers;
  };

  // Dynamically render table rows and cells
  const renderTableRows = (data) => {
    return data.map((item, index) => (
      <tr key={index}>
        {Object.values(item).map((value, cellIndex) => (
          <td key={cellIndex}>{value}</td>
        ))}
      </tr>
    ));
  };

  return (
    <Card className="shadow mb-4">
      <Card.Header className="d-flex flex-row align-items-center justify-content-between">
        <h6>Use API Dataset</h6>
      </Card.Header>
      <Card.Body>
        {flashMessage && (
          <div className="alert alert-info" role="alert">
            {flashMessage}
          </div>
        )}
        {loading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : data && data.length > 0 ? (
          <>
            <div className="table-container">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    {generateTableHeaders(data).map((header, index) => (
                      <th key={index}>{header}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>{renderTableRows(data)}</tbody>
              </Table>
            </div>
            <div>
              <Button
                variant={!termsAccepted ? "warning" : "primary"}
                onClick={() => setShowTermsModal(true)}
                className="mb-2"
              >
                <b>View Terms and Conditions</b>
              </Button>
              <br />
              <TermsAndConditionsModal
                show={showTermsModal}
                onHide={() => setShowTermsModal(false)}
                onChanged={handleTermsAccept}
              />
              {!termsAccepted && (
                <Alert variant="danger">
                  Please read and acknowledge the terms and conditions by
                  ticking the checkbox at the bottom.
                </Alert>
              )}
              <div className="d-flex gap-2 mt-2">
                <Button
                  variant="primary"
                  onClick={() => handleDownload("csv")}
                  disabled={!termsAccepted}
                >
                  Download CSV
                </Button>
                <Button
                  variant="info"
                  onClick={() => handleDownload("json")}
                  disabled={!termsAccepted}
                >
                  Download JSON
                </Button>
                <Button
                  variant="primary"
                  onClick={() =>
                    registerForApiAccess(apiRequest, null, setLoading, navigate)
                  }
                  disabled={!termsAccepted}
                >
                  Get API Access
                </Button>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center my-4">
            <i
              className="fas fa-exclamation-triangle mb-2"
              style={{ fontSize: "24px" }}
            ></i>
            <p className="font-weight-bold mb-1">
              No Data Available For Your Selection.
            </p>
            <p>Please contact support.</p>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default GetData;
