// hooks/useFileDownloadRequest.js
import useStore from "../store/store";
import { API_BASE_URL } from "../config"; // Adjust the path as necessary

const useFileDownloadRequest = () => {
  const accessToken = useStore((state) => state.authData?.access_token);

  const downloadRequest = async (url, fileType) => {
    const mimeTypes = {
      csv: "text/csv",
      pdf: "application/pdf",
      json: "application/json",
    };

    if (!url.startsWith("http")) {
      url = `${API_BASE_URL}${url}`;
    }

    const headers = {
      Authorization: `Bearer ${accessToken}`,
      Accept: mimeTypes[fileType],
    };

    const response = await fetch(url, { headers });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.blob();
  };

  return downloadRequest;
};

export default useFileDownloadRequest;
