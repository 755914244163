import React, { useState, useEffect } from "react";
// Import Form component from react-bootstrap
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import "reactjs-popup/dist/index.css";
import "chartjs-adapter-date-fns";
import useApiRequest from "../../hooks/useApiRequest";
import LoadingOverlay from "../../components/layout/components/LoadingOverlay";
import useFileDownloadRequest from "../../hooks/useFileDownloadRequest";

const Statistics = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [horizBarChart, setHorizBarChart] = useState(null);
  var [timeChart, setTimeChart] = useState(null);

  const apiRequest = useApiRequest();
  const fileDownloadRequest = useFileDownloadRequest();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await apiRequest({
        url: "/stats/json",
        method: "GET",
      });
      const jsonData = await response.json();

      if (jsonData) {
        setData(jsonData);
        processRatios(jsonData);
        updateHorizontalBarChart(jsonData);
        processLoginAnalysis(jsonData.loginFrequencyByDate);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateHorizontalBarChart = (logTypeCounts) => {
    const { submitCount, approveCount, rejectCount } =
      getLogTypeCounts(logTypeCounts);
    const approvalRate =
      submitCount > 0 ? (approveCount / submitCount) * 100 : "0.00";
    const ctx1 = document.getElementById("dataRequestApproval");

    if (horizBarChart) {
      horizBarChart.destroy();
    }

    setHorizBarChart(
      new Chart(
        ctx1,
        createBarChartConfig(
          submitCount,
          approveCount,
          rejectCount,
          approvalRate?.toFixed(2)
        )
      )
    );
  };

  const getLogTypeCounts = (jsonData) => {
    const submitCount = jsonData["logTypeCounts"]["data_request_submit"];
    const approveCount = jsonData["logTypeCounts"]["data_request_approve"];
    const rejectCount = jsonData["logTypeCounts"]["data_request_reject"];

    return { submitCount, approveCount, rejectCount };
  };

  const createBarChartConfig = (
    submitCount,
    approveCount,
    rejectCount,
    approvalRate
  ) => ({
    type: "bar",
    data: {
      labels: ["Total Requests", "Approved Requests", "Rejected Requests"],
      datasets: [
        {
          label: "Requests",
          data: [submitCount, approveCount, rejectCount],
          backgroundColor: [
            "rgba(75, 192, 192, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 99, 132, 0.2)",
          ],
          borderColor: [
            "rgba(75, 192, 192, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 99, 132, 1)",
          ],
          borderWidth: 1,
        },
      ],
    },
    options: {
      indexAxis: "y",
      elements: { bar: { borderWidth: 2 } },
      responsive: true,
      plugins: {
        legend: { position: "right" },
        title: {
          display: true,
          text: `Data Requests Analysis - Approval Rate: ${approvalRate}%`,
        },
      },
    },
  });

  const getLogTypesCount = () => {
    // Directly return logTypeCounts from stats
    return data.logTypeCounts || {};
  };

  const chartDataLogCount = {
    labels: Object.keys(getLogTypesCount()),
    datasets: [
      {
        label: "Log Types Count",
        backgroundColor: "rgba(75,192,192,1)",
        borderColor: "rgba(0,0,0,1)",
        borderWidth: 2,
        data: Object.values(getLogTypesCount()),
      },
    ],
  };

  const chartOptions = {
    scales: {
      x: {
        beginAtZero: true,
      },
      y: {
        beginAtZero: true,
      },
    },
  };

  // Function to process and visualize data
  const processRatios = (data) => {
    // // Extract relevant information for analytics
    // const submitCount = data.filter(
    //   (entry) => entry.logType === "data_request_submit"
    // ).length;
    // const approveCount = data.filter(
    //   (entry) => entry.logType === "data_request_approve"
    // ).length;
    // const downloadCount = data.filter(
    //   (entry) => entry.logType === "data_request_download"
    // ).length;
    // const apiDownloadCount = data.filter(
    //   (entry) => entry.logType === "api_data_request_download"
    // ).length;

    // Calculate ratios
    const submitToApproveRatio = data.submitToApproveRatio;
    const approveToPortalDownloadRatio = data.approveToPortalDownloadRatio;
    const approveToApiDownloadRatio = data.approveToApiDownloadRatio;

    // Display ratios in cards and create pie charts
    displayRatioCard(
      "dataRequestSubmitToApproveRatio",
      "Data Request Submit to Approve Ratio",
      submitToApproveRatio?.toFixed(2)
    );

    displayRatioCard(
      "dataRequestApproveToPortalDownloadRatio",
      "Data Request Approve to Portal Download Ratio",
      approveToPortalDownloadRatio?.toFixed(2)
    );

    displayRatioCard(
      "dataRequestApproveToApiDownloadRatio",
      "Data Request Approve to API Download Ratio",
      approveToApiDownloadRatio?.toFixed(2)
    );
  };

  // Function to create a card for ratio display
  const displayRatioCard = (divId, title, ratio) => {
    const cardDiv = document.getElementById(divId);
    cardDiv.innerHTML = `
      <div class="card-title" style="padding:5px">${title}</div>
      <div class="card-content" style="padding:5px">
        <strong>${ratio}</strong>
      </div>
    `;
  };

  const processLoginAnalysis = (loginFrequencyByDate) => {
    const timestamps = Object.keys(loginFrequencyByDate).map(
      (date) => new Date(date)
    ); // Convert string dates to Date objects for better chart handling
    const counts = Object.values(loginFrequencyByDate);

    const ctx2 = document
      .getElementById("loginTimeSeriesChart")
      .getContext("2d");

    // Destroy the existing Chart instance if it exists
    if (timeChart) {
      timeChart.destroy();
    }

    timeChart = new Chart(ctx2, {
      type: "line",
      data: {
        labels: timestamps,
        datasets: [
          {
            label: "Number of Logins",
            data: counts,
            borderColor: "rgba(75, 192, 192, 1)",
            backgroundColor: "rgba(75, 192, 192, 0.1)",
            borderWidth: 2,
            pointRadius: 3,
            pointBackgroundColor: "rgba(75, 192, 192, 1)",
            fill: false,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
          x: {
            type: "time",
            time: {
              unit: "day",
              tooltipFormat: "MMM d", // Corrected here from 'MMM D' to 'MMM d'
              displayFormats: {
                day: "MMM d", // Corrected here as well
              },
            },
            title: {
              display: true,
              text: "Date",
            },
          },
          y: {
            beginAtZero: true,
            title: {
              display: true,
              text: "Logins",
            },
          },
        },
        plugins: {
          legend: {
            display: true,
          },
          title: {
            display: true,
            text: "Login Events Time Series",
          },
          tooltip: {
            mode: "index",
            intersect: false,
            callbacks: {
              label: function (tooltipItem) {
                return `Logins: ${tooltipItem.raw}`;
              },
            },
          },
        },
      },
    });
  };

  const handleDownload = async (fileType) => {
    setLoading(true);
    const endpoint = `/stats/${fileType}`;

    try {
      const blob = await fileDownloadRequest(endpoint, fileType);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `stats_data.${fileType}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Card className="shadow mb-4">
      {loading && <LoadingOverlay loading={loading} />}

      <Card.Header className="py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 className="unverify">Utilization Statistics</h6>
      </Card.Header>
      <Card.Body>
        <Container fluid className="pb-3">
          <div className="mb-2">
            <Button
              variant="info"
              onClick={() => handleDownload("csv")}
              className="me-2"
            >
              Download as CSV
            </Button>
            <Button variant="warning" onClick={() => handleDownload("pdf")}>
              Download as PDF
            </Button>
          </div>
          <Row>
            <Col md={8} sm={8} xs={12}>
              <Card>
                <Card.Body>
                  <div className="chart">
                    <Bar data={chartDataLogCount} options={chartOptions} />
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} sm={4} xs={12}>
              <Card>
                <Card.Body>
                  <div>
                    <div
                      id="dataRequestSubmitToApproveRatio"
                      className="card my-1"
                    ></div>
                    <div
                      id="dataRequestApproveToPortalDownloadRatio"
                      className="card my-1"
                    ></div>
                    <div
                      id="dataRequestApproveToApiDownloadRatio"
                      className="card my-1"
                    ></div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>

        <Container fluid className="pb-3">
          <Row>
            <Col md={6} sm={6} xs={12}>
              <Card>
                <Card.Body>
                  <div className="chart">
                    <canvas id="dataRequestApproval"></canvas>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col md={6} sm={6} xs={12}>
              <Card>
                <Card.Body style={{ height: "42vh" }}>
                  <div>
                    <canvas id="loginTimeSeriesChart"></canvas>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Statistics;
