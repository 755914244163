export const registerForApiAccess = async (
  apiRequest, // Add apiRequest as an argument
  setClientData,
  setLoading,
  navigate
) => {
  setLoading(true);
  try {
    const response = await apiRequest({
      url: "/client/register",
      method: "POST",
    });

    if (response?.ok) {
      const jsonData = await response.json();
      setClientData(jsonData);
      navigate("/u/api-access");
      window.location.reload();
    } else if (response?.status === 409) {
      // This is a conflict, meaning the user already has an API key. Redirect to the API access page.
      navigate("/u/api-access");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    navigate("/u/api-access");
  } finally {
    setLoading(false);
  }
};
