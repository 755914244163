import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { Toaster } from "react-hot-toast";
import NavBar from "./components/NavBar";
import Sidebar from "./components/Sidebar";
import Footer from "./components/Footer";

const BaseLayout = ({ children }) => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(
    window.innerWidth > 576
  );

  const toggleSidebar = () => setIsSidebarVisible(!isSidebarVisible);

  const location = useLocation();

  // Reset sidebar visibility on route change
  useEffect(() => {
    setIsSidebarVisible(window.innerWidth > 576);
  }, [location]);

  return (
    <Container fluid>
      <Row>
        <NavBar toggleSidebar={toggleSidebar} />
      </Row>
      <Row className="flex-grow-1">
        {isSidebarVisible && (
          // Conditionally render Sidebar
          <Col
            xs={12}
            sm={4}
            md={4}
            lg={3}
            xl={2}
            className="layout-sidebar px-0"
          >
            <Sidebar />
          </Col>
        )}
        <Col
          xs={isSidebarVisible ? 0 : 12}
          sm={isSidebarVisible ? 8 : 12}
          md={isSidebarVisible ? 8 : 12}
          lg={isSidebarVisible ? 9 : 12}
          xl={isSidebarVisible ? 10 : 12}
          className="layout-content p-0 pt-2 px-md-2 px-lg-4 px-sm-0"
        >
          {children}
        </Col>
      </Row>
      <Row>
        <Footer />
      </Row>
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{
          // Default options for specific types
          success: {
            duration: 5000,
            theme: {
              primary: "#198754",
              secondary: "white",
            },
          },
          error: {
            duration: 5000,
            theme: {
              primary: "#dc3545",
              secondary: "white",
            },
          },
        }}
      />
    </Container>
  );
};

export default BaseLayout;
