import React, { useCallback } from "react";
import { Card, Table, Badge, Spinner, Alert, Button } from "react-bootstrap";
import useStore from "../store/store";
import useApiRequest from "../hooks/useApiRequest";
import LoadingOverlay from "../components/layout/components/LoadingOverlay";
import toast from "react-hot-toast";

const Unverified = () => {
  const { user } = useStore((state) => ({
    user: state.user,
  }));
  const apiRequest = useApiRequest();

  // Optional: Add a loading state if user data might take time to load
  const [isLoading, setIsLoading] = React.useState(true);
  const [isResendingEmail, setIsResendingEmail] = React.useState(false);

  React.useEffect(() => {
    if (user) {
      setIsLoading(false);
    }
  }, [user]);

  const sendVerificationEmail = useCallback(async () => {
    try {
      setIsResendingEmail(true);
      const response = await apiRequest({
        method: "POST",
        url: `/auth/send-verify-email`,
      });

      // Check the response status
      if (response.ok) {
        console.log("Verification email sent successfully.");
        toast.success("Verification email successfully sent");
      } else {
        console.error("Error sending verification email:", response.statusText);
        toast.error("There was an error sending the verification email");
      }
    } catch (error) {
      console.error("Error sending verification email:", error);
      toast.error("There was an error sending the verification email");
    } finally {
      setIsResendingEmail(false);
    }
  }, [apiRequest]);

  return (
    <Card className="shadow">
      <Card.Header>
        <h6>Application Status</h6>
      </Card.Header>

      <Card.Body>
        {isResendingEmail && <LoadingOverlay loading={isResendingEmail} />}
        {isLoading ? (
          <div className="text-center">
            <Spinner animation="border" role="status">
              <span className="sr-only">Loading...</span>
            </Spinner>
          </div>
        ) : user ? (
          <>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>Email</th>
                  <th>Firstname</th>
                  <th>Lastname</th>
                  <th>Approval Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{user.email}</td>
                  <td>{user.firstName}</td>
                  <td>{user.lastName}</td>
                  <td>
                    {user.groups && user.groups.length > 0 ? (
                      <>
                        {!user.groups.some(
                          (group) => group.name === "rejected-tenants"
                        ) &&
                          user.groups.map((group, index) => (
                            <Badge
                              key={index}
                              pill
                              className={
                                !user.emailVerified ||
                                group.name === "pending-tenants"
                                  ? "bg-danger"
                                  : group.name === "approved-tenants"
                                  ? "bg-success"
                                  : "bg-primary"
                              }
                              style={{ marginRight: "5px" }}
                            >
                              {!user.emailVerified
                                ? "Pending email verification"
                                : group.name === "pending-tenants"
                                ? "Pending Administrator's Approval"
                                : group.name === "approved-tenants"
                                ? "Approved"
                                : group.name}
                            </Badge>
                          ))}
                        {user.groups.some(
                          (group) => group.name === "rejected-tenants"
                        ) && (
                          <Badge pill className="bg-danger">
                            User not approved
                          </Badge>
                        )}
                      </>
                    ) : (
                      "No groups found"
                    )}
                    {user.groups.some(
                      (group) => group.name === "rejected-tenants"
                    ) && (
                      <>
                        <h6 className="mt-2">Admin feedback:</h6>
                        {user.attributes?.rejectReason?.length > 0
                          ? user.attributes?.rejectReason[0]
                          : "No reason given"}
                      </>
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
            {!user.emailVerified && (
              <Alert variant="warning">
                <h5>Email Verification Needed</h5>
                <p>
                  To enable admin approval of your account, please verify your
                  email address. Remember to check the spam/junk email folder
                </p>
                <Button onClick={() => sendVerificationEmail()}>
                  Resend Verification Email
                </Button>
              </Alert>
            )}
          </>
        ) : (
          <p>No user data available.</p>
        )}
        {user &&
          user.groups.some((group) => group.name === "rejected-tenants") && (
            <p>
              If you believe the account rejection was in error, please send an
              email with any relevant documentation to support reassessment of
              the account.
            </p>
          )}
      </Card.Body>
    </Card>
  );
};

export default Unverified;
