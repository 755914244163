import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import useStore from "../../store/store";
import useLogout from "../../hooks/useLogout";
import { useNavigate, NavLink, Link } from "react-router-dom";

import MinistryLogo from "../logo/MinistryLogo";

const HomeNav = () => {
  const { isAuthenticated, isAdmin, isApproved } = useStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    isAdmin: state.isAdmin,
    isApproved: state.isApproved,
  }));
  const navigate = useNavigate();
  const logout = useLogout();

  //logout a user
  const handleLogout = (event) => {
    event.preventDefault(); // Prevent default link behavior
    logout();
  };

  const handleDashboardClick = (event) => {
    event.preventDefault(); // Prevent default link behavior
    if (!isApproved) {
      navigate("/unverified");
    } else {
      const redirectRoute = isAdmin
        ? "/a/stakeholder-approval"
        : "/u/new-data-request";
      navigate(redirectRoute);
    }
  };

  return (
    <Navbar
      expand="lg"
      bg="white"
      variant="light"
      className="py-0 px-4 container-fluid nav-bar bg-transparent"
    >
      <Navbar.Brand className="my-1">
        <MinistryLogo onClick={() => navigate("/")} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav">
        <FontAwesomeIcon icon={faBars} />
      </Navbar.Toggle>

      <Navbar.Collapse id="navbarCollapse">
        <Nav className="ms-auto">
          <NavLink
            exact
            to="/"
            className="nav-item nav-link"
            activeClassName="active"
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className="nav-item nav-link"
            activeClassName="active"
          >
            About
          </NavLink>
          <a
            href="https://agdata.go.ke/projects"
            className="nav-item nav-link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Projects
          </a>
          <a
            href="/resources"
            className="nav-item nav-link"
            rel="noopener noreferrer"
          >
            Resources
          </a>
          <NavLink
            to="/contact"
            className="nav-item nav-link"
            activeClassName="active"
          >
            Contact
          </NavLink>
          <NavLink
            to="/faq"
            className="nav-item nav-link"
            activeClassName="active"
          >
            FAQ
          </NavLink>
        </Nav>
        <div>
          {isAuthenticated ? (
            <>
              <Link
                onClick={handleDashboardClick}
                className="btn btn-primary px-3 me-2"
              >
                Dashboard
              </Link>
              <Link onClick={handleLogout} className="btn px-3">
                Logout
              </Link>
            </>
          ) : (
            <Link to="/login" className="btn btn-primary px-3 me-2">
              Login
            </Link>
          )}
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default HomeNav;
