import React, { useState, useEffect } from "react";
// Import Form component from react-bootstrap
import { Container, Row, Col, Card, Button, Form } from "react-bootstrap";
import Chart from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import "reactjs-popup/dist/index.css";
import "chartjs-adapter-date-fns";
import useApiRequest from "../../hooks/useApiRequest";
import LoadingOverlay from "../../components/layout/components/LoadingOverlay";
import useFileDownloadRequest from "../../hooks/useFileDownloadRequest";
import jsPDF from 'jspdf';
import { saveAs } from "file-saver";

const Reports = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [statusCounts, setStatusCounts] = useState({});
  const [statusLabels, setStatusLabels] = useState([]);
  const [statusOngoingCounts, setStatusOngoingCounts] = useState({});
  const [selectedTenantType, setSelectedTenantType] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("");
  const [selectedRegistry, setSelectedRegistry] = useState("");
  const [tenantTypes, setTenantTypes] = useState([]);
  const [organizations, setOrganizations] = useState([]);
  const [registries, setRegistries] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [totalDataRequests, setTotalDataRequests] = useState("0");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [relatedEntries, setRelatedEntries] = useState([]);
  const [entriesForStatus, setEntriesForStatus] = useState([]);

  const apiRequest = useApiRequest();

  useEffect(() => {
    fetchData();
  }, [selectedTenantType, selectedOrganization, selectedRegistry]);

  const fetchData = async () => {
    setLoading(true);

    try {
      const response = await apiRequest({
        url: "/data-requests/all?limit=600",
        method: "GET",
      });
      
      const jsonData = await response.json();
      console.log("Fetched data from API:", jsonData); // Logging fetched data
    
      if (jsonData) {
        setData(jsonData);
        processData(jsonData); // Call processData to update the counts
        populateDropdowns(jsonData); // Populate dropdowns with filtered data
        updateChart(); // Update chart data after populating dropdowns
        calculateTotalRequests(jsonData); // Calculate total requests based on filters
      } else {
        console.error("Error: Received null or undefined response data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const calculateTotalRequests = (data) => {
    const filteredData = data.dataRequests.filter(request =>
      (selectedTenantType === "" || request.tenantType === selectedTenantType) &&
      (selectedOrganization === "" || request.organization === selectedOrganization) &&
      (selectedRegistry === "" || request.registry === selectedRegistry)
    );
  
    const totalRequests = filteredData.length;
  
    // Update the state with the total number of requests
    setTotalDataRequests(totalRequests.toString());
  };

  const processData = (data) => {
    const statusCountsObj = {};
    const statusLabelsArr = [];
    const totalDataRequests = data.totalCount;
    const statusOngoingCountsTot = 0;

    data.dataRequests.forEach(request => {
      const status = request.status;
      if (!statusCountsObj[status]) {
        statusCountsObj[status] = 1;
        statusLabelsArr.push(status);
      } else {
        statusCountsObj[status]++;
      }
      //statusOngoingCountsTot++;
    });

    setTotalDataRequests(totalDataRequests);
    setStatusCounts(statusCountsObj);
    setStatusLabels(statusLabelsArr);
    //setStatusOngoingCounts(statusOngoingCountsTot);
  };

  const populateDropdowns = (data) => {
    const tenantTypes = [...new Set(data.dataRequests.map(request => request.tenantType))];
    const organizations = [...new Set(data.dataRequests.map(request => request.organization))];
    const registries = [...new Set(data.dataRequests.map(request => request.registry))];

    // Update the state with filtered options
    setTenantTypes(tenantTypes);
    setOrganizations(organizations);
    setRegistries(registries);
  };

  const updateChart = (option) => {
    const filteredData = data.dataRequests.filter(request =>
      (selectedTenantType === "" || request.tenantType === selectedTenantType) &&
      (selectedOrganization === "" || request.organization === selectedOrganization) &&
      (selectedRegistry === "" || request.registry === selectedRegistry)
    );
    const statusCountsObj = {};

    filteredData.forEach(request => {
      const status = request.status;
      if (!statusCountsObj[status]) {
        statusCountsObj[status] = 1;
      } else {
        statusCountsObj[status]++;
      }
    });

    setStatusCounts(statusCountsObj);
    setStatusLabels(Object.keys(statusCountsObj));
    setSelectedStatus("");
    setEntriesForStatus("");
  };

  const generateBarChartData = () => {
    const backgroundColors = [
      "rgba(75, 192, 192, 0.2)",
      "rgba(54, 162, 235, 0.2)",
      "rgba(255, 99, 132, 0.2)",
      "rgba(25, 49, 13, 0.2)",
      "rgba(55, 129, 32, 0.2)",
    ];
    const borderColors = [
      "rgba(75, 192, 192, 1)",
      "rgba(54, 162, 235, 1)",
      "rgba(255, 99, 132, 1)",
      "rgba(25, 49, 13, 1)",
      "rgba(55, 129, 32, 1)",
    ];

    const datasets = [{
      label: "Count",
      data: statusLabels.map(label => statusCounts[label] || 0),
      backgroundColor: backgroundColors.slice(0, statusLabels.length),
      borderColor: borderColors.slice(0, statusLabels.length),
      borderWidth: 1,
    }];

    return {
      labels: statusLabels,
      datasets: datasets,
    };
  };

  const handleDownloadPDF = () => {
    // Code to download the chart as a PDF
    const canvas = document.querySelector("canvas");
    const pdf = new jsPDF();
  
    // Add header indicating the filters applied
    const title = "Agricultural Sector Data Gateway [ASDG]";
    const acronym = "Data Requests Analysis";
    const filtersApplied = `Filters Applied`;
    const filtersText = `Tenant Type: ${selectedTenantType || "None"}\nOrganization: ${selectedOrganization || "None"}\nRegistry: ${selectedRegistry || "None"}`;
    const totRequests = `Total Data Requests: ${totalDataRequests}`;
  
    // Calculate text width
    const titleWidth = pdf.getStringUnitWidth(title) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    const acronymWidth = pdf.getStringUnitWidth(acronym) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    const filtersAppliedWidth = pdf.getStringUnitWidth(filtersApplied) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
    const filtersTotRequestsWidth = pdf.getStringUnitWidth(totRequests) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
  
    // Calculate position to center text horizontally
    const titleX = (pdf.internal.pageSize.getWidth() - titleWidth) / 2;
    const acronymX = (pdf.internal.pageSize.getWidth() - acronymWidth) / 2;
    const filtersAppliedX = (pdf.internal.pageSize.getWidth() - filtersAppliedWidth) / 2;
    const filtersTotRequestsX = (pdf.internal.pageSize.getWidth() - filtersTotRequestsWidth) / 2;
  
    // Add title with bold and underline styles
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(14);
    pdf.text(titleX, 20, title, { align: "left" });

    // Add title with bold and underline styles
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(14);
    pdf.text(acronymX, 30, acronym, { align: "left" });
  
    // Add Filters Applied text
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(10, 40, filtersApplied, { align: "left" });
  
    // Add Filters Applied details
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(12);
    pdf.text(10, 50, filtersText);
  
    // Add title with bold and underline styles
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text(filtersTotRequestsX, 65, totRequests, { align: "left" });

    // Calculate width and height for the image based on canvas dimensions and aspect ratio
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const aspectRatio = canvasWidth / canvasHeight;
    const pdfWidth = pdf.internal.pageSize.getWidth() - 20; // Subtracting padding
    const pdfHeight = pdfWidth / aspectRatio;
  
    // Add image to PDF and fit it within the page
    pdf.addImage(canvas.toDataURL("image/png"), "PNG", 10, 67, pdfWidth, pdfHeight);
  
    // Add watermark for date and time of downloading
    const currentDate = new Date().toLocaleString();
    pdf.setFontSize(8);
    pdf.setTextColor(200);
    pdf.text(currentDate, 10, pdf.internal.pageSize.getHeight() - 10);
  
    // Save PDF
    pdf.save("chart_pdf.pdf");
  };
  
  const handleDownloadCSV = () => {
    // Code to download the chart data as a CSV file
    // Construct CSV content
    let csvContent = `AGRICULTURAL SECTOR DATA GATEWAY DATA REQUESTS ANALYSIS,\n`;
    csvContent += `[ASDG],\n\n`;
    csvContent += `FILTERS APPLIED\n`;
    csvContent += `Tenant Type: ${selectedTenantType || "None"}\n`;
    csvContent += `Organization: ${selectedOrganization || "None"}\n`;
    csvContent += `Registry: ${selectedRegistry || "None"}\n\n`;

    // Add data headers
    csvContent += "STATUS,COUNT\n";

    // Add data rows
    statusLabels.forEach((label, index) => {
      csvContent += `${label},${statusCounts[label] || 0}\n`;
    });

    // Add watermark for date and time of downloading
    const currentDate = new Date().toLocaleString();
    csvContent += `\n`;
    csvContent += `Total Data Requests:, ${totalDataRequests}\n\n`;
    csvContent += `Date Generated:,`;
    csvContent += `${currentDate }\n\n`;

    // Create a Blob object
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });

    // Trigger download
    saveAs(blob, "chart_data.csv");
  };

  const options = {
    onClick: (event, elements) => {
      if (elements.length > 0) {
        const statusLabel = statusLabels[elements[0].index];
        const entriesStatus = statusCounts[statusLabel] || 0;
        setSelectedStatus(statusLabel);
        setEntriesForStatus(entriesStatus);
      }
    }
  };

  return (
    <Card className="shadow mb-4">
      {loading && <LoadingOverlay loading={loading} />}

      <Card.Header className="py-3 d-flex flex-row align-items-center justify-content-between">
        <h6 className="unverify">Data Requests Analysis</h6>
      </Card.Header>
      <Card.Body>
        <Container>
          <div className="mb-2">
            <Button
              variant="info"
              onClick={() => handleDownloadCSV()}
              className="me-2"
            >
              Download as CSV
            </Button>
            <Button variant="warning" onClick={() => handleDownloadPDF()}>
              Download as PDF
            </Button>
          </div>
        </Container>
        <Container>
          <Card className="shadow mb-8">
            <Card.Body>
              <Row>
                <div className="col-md-4 col-sm-4 col-12">
                  <Form.Control
                    as="select"
                    value={selectedTenantType}
                    onChange={(e) => {
                      setSelectedTenantType(e.target.value);
                      setSelectedOption(e.target.value); // Update selectedOption here
                      fetchData(); // Fetch data when selecting a new option
                    }}
                  >
                    <option value="">Select Tenant Type</option>
                    {tenantTypes.map((type, index) => (
                      <option key={index} value={type}>{type}</option>
                    ))}
                  </Form.Control>
                </div>
                <div className="col-md-4 col-sm-4 col-12">
                  <Form.Control
                    as="select"
                    value={selectedOrganization}
                    onChange={(e) => {
                      setSelectedOrganization(e.target.value);
                      setSelectedOption(e.target.value); // Update selectedOption here
                      fetchData(); // Update chart when selecting a new option
                    }}
                  >
                    <option value="">Select Organization</option>
                    {organizations.map((org, index) => (
                      <option key={index} value={org}>{org}</option>
                    ))}
                  </Form.Control>
                </div>
                <div className="col-md-4 col-sm-4 col-12">
                  <Form.Control
                    as="select"
                    value={selectedRegistry}
                    onChange={(e) => {
                      setSelectedRegistry(e.target.value);
                      setSelectedOption(e.target.value); // Update selectedOption here
                      fetchData(); // Update chart when selecting a new option
                    }}
                  >
                    <option value="">Select Registry</option>
                    {registries.map((reg, index) => (
                      <option key={index} value={reg}>{reg}</option>
                    ))}
                  </Form.Control>
                </div>
              </Row>
            </Card.Body>
          </Card>
          <div>&nbsp;</div>
        </Container>
        <Container fluid className="pb-3">
          <div>
            <Row>
              <Col md={8} sm={8} xs={12}>
                <Card className="shadow mb-8">
                  <Card.Body>
                    <h6 className="m-0 font-weight-bold text-primary">Requests Status</h6>
                      <div className="chart">
                        {loading && <LoadingOverlay loading={loading} />}
                        <div style={{ width: "100%", margin: "auto" }}>
                          <Bar data={generateBarChartData()} options={options} />
                        </div>
                      </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} sm={4} xs={12}>
                <Card className="shadow mb-4">
                  <Card.Body className="text-center">
                    <h6 className="m-0 font-weight-bold text-primary">Total Data Requests</h6>
                    <div className="mt-3">
                      <h1 className="text-gray-800">{totalDataRequests}</h1>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
        <Container fluid className="pb-3">
          <div>
            {selectedStatus && (
              <Row>
                <Col>
                  <Card className="shadow mb-4">
                    <Card.Body>
                      <h6 className="m-0 font-weight-bold text-primary">Data Requests with Status <u>"{selectedStatus}"</u>: {entriesForStatus}</h6>
                      <ul>
                        {relatedEntries.map((entry, index) => (
                          <li key={index}>{entry.item}</li>
                        ))}
                      </ul>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            )}
          </div>
        </Container>
      </Card.Body>
    </Card>
  );
};

export default Reports;