import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "chart.js/auto";
import "reactjs-popup/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { vscDarkPlus } from "react-syntax-highlighter/dist/esm/styles/prism";
import copy from "copy-to-clipboard";
import toast from "react-hot-toast";
import { registerForApiAccess } from "../../utils/apiServices";
import CopyButton from "../../components/CopyButton";
import useApiRequest from "../../hooks/useApiRequest";

const CodeBlock = ({ value }) => {
  const handleCopy = () => {
    copy(value);
    toast.success("Code copied to clipboard");
  };

  return (
    <div>
      <SyntaxHighlighter language="json" style={vscDarkPlus}>
        {value}
      </SyntaxHighlighter>
      <Button variant="outline-primary" size="sm" onClick={handleCopy}>
        Copy
      </Button>
    </div>
  );
};

const ApiAccess = () => {
  const [loading, setLoading] = useState(true);
  const [clientData, setClientData] = useState(null); // New state for storing client data
  const [requestApiAccess, setRequestApiAccess] = useState(false);
  const [show, setShow] = useState(false);
  const [showSecret, setShowSecret] = useState(false);
  const [markdown, setMarkdown] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const apiRequest = useApiRequest();

  useEffect(() => {
    fetch("/assets/md/apiAccessDocumentation.md")
      .then((response) => response.text())
      .then((text) => setMarkdown(text));
  }, []);

  const handleToggleSecret = () => {
    setShowSecret(!showSecret);
  };

  const fetchApiCreds = async () => {
    setLoading(true);
    try {
      const response = await apiRequest({
        url: "/client/introspect",
        method: "GET",
      });

      if (response.ok) {
        const jsonData = await response.json();
        setClientData(jsonData); // Save data to clientData
        if (response.status === 409 || jsonData === null) {
          setRequestApiAccess(true); // Indicate that API access request is required
        }
      } else {
        console.error("Error fetching data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApiCreds(setClientData, setLoading, setRequestApiAccess);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   if (clientData) {
  //     // Perform actions when clientData is updated
  //     // For example, navigate to a new route or refresh the component
  //     navigate("/some-route"); // Navigate to a new route
  //     // or you can force a component refresh
  //   }
  // }, [clientData, navigate]);
  //fetch Api Client Introspect

  const navigate = useNavigate();

  return (
    <Card className="shadow mb-4">
      <Card.Header className="d-flex flex-row align-items-center justify-content-between">
        <h6>API Access Details</h6>
      </Card.Header>
      <Container className="mt-5 pb-5">
        {loading ? (
          <p>Loading...</p>
        ) : requestApiAccess ? (
          <Button
            variant="primary"
            onClick={() =>
              registerForApiAccess(
                apiRequest,
                setClientData,
                setLoading,
                navigate
              )
            }
          >
            Request API Access
          </Button>
        ) : (
          <Row>
            <Col md={4}>
              <Card className="h-100">
                <Card.Header>Account Holder</Card.Header>
                <Card.Body>
                  <Card.Text>
                    Name: {clientData?.name.split(",")[0]}
                    <br />
                    Email: {clientData?.name.split(",")[1]}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="h-100">
                <Card.Header>Client ID</Card.Header>
                <Card.Body>
                  <Card.Text>
                    {clientData?.clientId}
                    <CopyButton text={clientData?.clientId} />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="h-100">
                <Card.Header>Client Secret</Card.Header>
                <Card.Body>
                  <Card.Text>
                    {showSecret ? clientData?.secret : "********"}
                    <Button variant="link" onClick={handleToggleSecret}>
                      {showSecret ? "Hide" : "Show"}
                    </Button>
                    <CopyButton text={clientData?.secret} />
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
        <>
          {/* Button to trigger modal */}
          <Button variant="primary" onClick={handleShow} className="mt-4">
            View API Documentation
          </Button>

          {/* Modal containing API documentation */}
          <Modal show={show} onHide={() => setShow(false)} size="xl" scrollable>
            <Modal.Header closeButton>
              <Modal.Title>API Access Documentation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                components={{
                  code({ node, inline, className, children, ...props }) {
                    const match = /language-(\w+)/.exec(className || "");
                    return !inline && match ? (
                      <CodeBlock
                        value={String(children).replace(/\n$/, "")}
                        {...props}
                      />
                    ) : (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  },
                }}
              >
                {markdown}
              </ReactMarkdown>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShow(false)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Container>
    </Card>
  );
};

export default ApiAccess;
