import React, { useState } from "react";
import { Table, Button } from "react-bootstrap";
import TenantsApprovalTableRow from "./TenantsApprovalTableRow";

const TenantsApprovalTable = ({ users, loading }) => {
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedUsers = users.slice(startIndex, endIndex);

  return (
    <>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Email</th>
            <th>Type</th>
            <th>Organization</th>
            <th>Designation</th>
            <th>County</th>
            <th>Contacts</th>
            <th>Action</th>
            {/* Add more headers as needed */}
          </tr>
        </thead>
        <tbody>
          {paginatedUsers.map((user) => (
            <TenantsApprovalTableRow key={user.id} user={user} />
          ))}
        </tbody>
      </Table>
      <div className="d-flex justify-content-between">
        <Button
          variant="primary"
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span>{` Page ${currentPage} of ${Math.ceil(
          users.length / itemsPerPage
        )} `}</span>
        <Button
          variant="primary"
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === Math.ceil(users.length / itemsPerPage)}
        >
          Next
        </Button>
      </div>
    </>
  );
};

export default TenantsApprovalTable;
