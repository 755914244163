import React from "react";
import coatOfArms from "../../images/Coat_of_arms_of_Kenya.png";

function MinistryLogo({ onClick }) {
  return (
    <div
      className="ministry-logo"
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      <div className="logo-column">
        <img
          src={coatOfArms}
          alt="Coat of Arms"
          className="logo-coat-of-arms"
        />
        <div className="caption">REPUBLIC OF KENYA</div>
      </div>
      <div className="flag-divider"></div>
      <div className="logo-text">
        <div className="text-top">Ministry of Agriculture &</div>
        <div className="text-bottom">Livestock Development</div>
      </div>
    </div>
  );
}

export default MinistryLogo;
