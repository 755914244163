import React from "react";
import { Modal, Button, ListGroup, Alert } from "react-bootstrap";
import { getCountyName } from "../../utils/getCountiesFromCodes";

const DataRequestSummaryModal = ({ show, onHide, selections, onSubmit }) => {
  const {
    selectedCategory,
    selectedSubject,
    selectedTopics,
    selectedCounties,
    requestReason,
    selectedFields,
  } = selections;

  // Function to render selected topics as a comma-separated list
  const renderSelectedTopics = () => {
    return selectedTopics.length > 0
      ? selectedTopics.join(", ")
      : "None selected";
  };

  // Function to render selected counties as a comma-separated list

  const renderSelectedCounties = () => {
    return selectedCounties.length > 0
      ? getCountyName(selectedCounties.join(", "))
      : "None selected";
  };

  // Function to render selected fields as a comma-separated list
  const renderSelectedFields = () => {
    return selectedFields.length > 0
      ? selectedFields.map((field) => field.alias).join(", ")
      : "None selected";
  };

  return (
    <Modal show={show} onHide={onHide} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Confirm Your Selections</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Alert variant="warning" className="mb-2">
          Please review your selections below. Once submitted, your request
          <b> cannot</b> be edited.
        </Alert>
        <ListGroup>
          <ListGroup.Item>
            <strong>Category:</strong>{" "}
            {selectedCategory?.label || "None selected"}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Subject:</strong> {selectedSubject || "None selected"}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Topics:</strong> {renderSelectedTopics()}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Counties:</strong> {renderSelectedCounties()}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Selected Fields:</strong> {renderSelectedFields()}
          </ListGroup.Item>
          <ListGroup.Item>
            <strong>Reason for Request:</strong>{" "}
            {requestReason || "None provided"}
          </ListGroup.Item>

          <ListGroup.Item>
            <strong>Data Sharing Agreement:</strong>{" "}
            {document.getElementById("dsa")?.files[0]?.name ?? "Not attached"}
          </ListGroup.Item>
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Go Back and Edit
        </Button>
        <Button variant="primary" onClick={onSubmit}>
          Confirm and Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DataRequestSummaryModal;
