import React, { useState } from "react";
import { Accordion } from "react-bootstrap";

import HomeNav from "../../components/Home/HomeNavMenu";

import FaqQuestionItem from "../../components/Home/FaqQuestionItem";
import HomeFooter from "../../components/Home/HomeFooter";

const Resources = () => {
  return (
    <>
      <>
        <div className="container-xxl bg-white p-0">
          {/* Navbar Start */}

          <HomeNav />

          {/* Navbar Ends */}

          {/* Footer Starts */}

          <div className="container-xxl bg-white p-0">
            <div className="container-fluid" style={{ marginTop: "40px" }}>
              <div className="container">
                <h2 className="text-center asdg-title">
                  Resources
                </h2>
              </div>

              <div className="container mt-5">
                <Accordion defaultActiveKey="0">
            
                  <FaqQuestionItem
                    questionNumber={1}
                    question="Download the Data Governance Framework"
                    answer="This Data governance framework seeks to empower stakeholders with tools and
                    processes to deliver digital innovations targeting over 1.4 million farmers, including
                    the implementation initial use case of farmer registry which is key to providing credible
                    data for providing farmers with e-incentives and e-subsidy which has already been
                    successfully piloted."
                     
                    
                  /> <br/>
 
                
 <a
                    className="btn btn-primary py-3 px-5 mt-3"
                    target="_blank"
                    href="https://kilimo.go.ke/wp-content/uploads/2022/04/MoALFC-Data-Governance-Framework-2022.pdf"
                  >
                    Download Now
                  </a>
             
             

       
       

                  {/* Add more FAQQuestion components for other questions */}
                </Accordion>
              </div>
            </div>

            <HomeFooter />
          </div>

          {/* Footer Ends */}
        </div>
      </>
    </>
  );
};

export default Resources;



//Resources