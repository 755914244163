import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import BaseLayout from "../components/layout/BaseLayout";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import ForgotPassword from "../pages/ForgotPassword";
import NewDataRequest from "../pages/approved-user/NewDataRequest";
import Integrations from "../pages/admin/DataSources";
import Statistics from "../pages/admin/Statistics";
import Reports from "../pages/admin/Reports";
import UnauthorizedUser from "../pages/UnauthorizedUser";
import Unverified from "../pages/Unverified";
import TenantsApproval from "../pages/admin/TenantsApproval";
import AdminDataRequests from "../pages/admin/AdminDataRequests";
import UserDataRequests from "../pages/approved-user/UserDataRequests";
import GetData from "../pages/approved-user/GetData";
import TermsPage from "../pages/TermsPage";
import ApiAccess from "../pages/approved-user/ApiAccess";
import Home from "../pages/Home/Home";
import Faq from "../pages/Home/Faq";
import About from "../pages/Home/About";
import Contact from "../pages/Home/Contact";
import UserProfile from "../pages/approved-user/UserProfile";
import MarkdownView from "../components/MarkdownView";
import useStore from "../store/store";
import Resources from "../pages/Home/Resources";

const ProtectedRoute = ({
  children,
  isAdminRoute = false,
  isUnverifiedRoute = false,
}) => {
  const { isAuthenticated, isAdmin, isApproved } = useStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    isAdmin: state.isAdmin,
    isApproved: state.isApproved,
  }));
  // Get the current location to check the route prefix
  const location = useLocation();

  // Check if the user is not authenticated
  if (!isAuthenticated) {
    return (
      <Navigate
        to="/login"
        state={{ reason: "info-logged-out", nextRoute: location.pathname }}
      />
    );
  }

  // Check for admin-only routes
  if (isAdminRoute && !isAdmin) {
    return <Navigate to="/unauthorised" />;
  }

  // Check for user-only routes
  if (location.pathname.startsWith("/u") && isAdmin) {
    return <Navigate to="/unauthorised" />;
  }

  // Additional checks for unverified routes
  if (
    (isUnverifiedRoute && isApproved) ||
    (!isApproved && !isUnverifiedRoute)
  ) {
    return <Navigate to="/unauthorised" />;
  }

  return <BaseLayout>{children}</BaseLayout>;
};

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/resources" element={<Resources />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />

        <Route path="/terms-and-conditions" element={<TermsPage />} />

        <Route
          path="/unverified"
          element={
            <ProtectedRoute isUnverifiedRoute>
              <Unverified />
            </ProtectedRoute>
          }
        />

        {/* Protected Routes */}
        {/* /profile does not begin with /u so that admins can access it too */}
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/u/new-data-request"
          element={
            <ProtectedRoute>
              <NewDataRequest />
            </ProtectedRoute>
          }
        />
        <Route
          path="/u/data-requests"
          element={
            <ProtectedRoute>
              <UserDataRequests />
            </ProtectedRoute>
          }
        />
        <Route
          path="/u/data-requests/:requestId"
          element={
            <ProtectedRoute>
              <GetData />
            </ProtectedRoute>
          }
        />
        <Route
          path="/u/api-access"
          element={
            <ProtectedRoute>
              <ApiAccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/u/user-guide"
          element={
            <ProtectedRoute>
              <MarkdownView />
            </ProtectedRoute>
          }
        />

        {/* Admin-Only Routes */}
        <Route
          path="/a/email-verified-stakeholders-approval"
          element={
            <ProtectedRoute isAdminRoute>
              <TenantsApproval endpoint="email-verified" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/non-email-verified-stakeholders-approval"
          element={
            <ProtectedRoute isAdminRoute>
              <TenantsApproval endpoint="non-email-verified" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/all-stakeholders-approval"
          element={
            <ProtectedRoute isAdminRoute>
              <TenantsApproval endpoint="all" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/all-data-requests"
          element={
            <ProtectedRoute isAdminRoute>
              <AdminDataRequests endpoint="all" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/incoming-data-requests"
          element={
            <ProtectedRoute isAdminRoute>
              <AdminDataRequests endpoint="incoming" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/verified-data-requests"
          element={
            <ProtectedRoute isAdminRoute>
              <AdminDataRequests endpoint="made" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/approved-data-requests"
          element={
            <ProtectedRoute isAdminRoute>
              <AdminDataRequests endpoint="approved-checked" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/rejected-data-requests"
          element={
            <ProtectedRoute isAdminRoute>
              <AdminDataRequests endpoint="rejected-checked" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/impasse-data-requests"
          element={
            <ProtectedRoute isAdminRoute>
              <AdminDataRequests endpoint="impasse" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/maker-review-data-requests"
          element={
            <ProtectedRoute isAdminRoute>
              <AdminDataRequests endpoint="maker-review" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/checker-review-data-requests"
          element={
            <ProtectedRoute isAdminRoute>
              <AdminDataRequests endpoint="checker-review" />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/integrations"
          element={
            <ProtectedRoute isAdminRoute>
              <Integrations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/statistics"
          element={
            <ProtectedRoute isAdminRoute>
              <Statistics />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/reports"
          element={
            <ProtectedRoute isAdminRoute>
              <Reports />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/admin-guide"
          element={
            <ProtectedRoute>
              <MarkdownView />
            </ProtectedRoute>
          }
        />
        <Route
          path="/a/super-admin-guide"
          element={
            <ProtectedRoute>
              <MarkdownView />
            </ProtectedRoute>
          }
        />

        {/* Fallback for Unauthorized Access */}
        <Route path="/unauthorised" element={<UnauthorizedUser />} />

        {/* Catch-all Route for 404 - Redirects to Unauthorised for now */}
        <Route path="*" element={<Navigate to="/unauthorised" />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
