import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const TermsPage = () => {
  return (
    <Container className="pt-4 pb-4">
      <Row className="justify-content-center">
        <Col md={12}>
          <h2>
            {" "}
            Terms and Conditions for Agricultural Sector Data Gateway (ASDG)
          </h2>
          <p>
            <strong>Effective Date:</strong> The date of approval of the API
          </p>
          <p>
            These terms and conditions ("Terms") govern the use of data shared
            via Application Programming Interface ("API") by Ministry of
            Agriculture and Livestock Development, a State Actor, with its
            principal place of business at Kilimo House, Community, Nairobi,
            Kenya ("Provider") and its authorized users ("User" or "Users").
          </p>
          <h3>1. Data Sharing Overview</h3>
          <p>
            1.1 The User acknowledges and agrees that the data provided through
            the API is the proprietary information of Provider.
          </p>
          <p>
            1.2 The data shared herein may include, but is not limited to,
            Farmers’ Information, Agricultural Programmes activities and Actors
            in the Agricultural Programmes.
          </p>
          <h3>2. Data Usage</h3>
          <p>
            2.1 The User agrees to use the shared data solely for the purpose(s)
            expressly agreed upon between the parties, as per the agreed Data
            Sharing Agreement.
          </p>
          <p>
            2.2 The User shall not use the data for any unlawful, fraudulent, or
            malicious activities.
          </p>
          <p>
            2.3 The User shall implement and maintain appropriate security
            measures to protect the shared data from unauthorized access,
            disclosure, alteration, and destruction.
          </p>
          <h3>3. Privacy by Design</h3>
          <p>
            3.1 The User agrees to implement privacy by design principles in the
            development and operation of any systems or applications that
            process the shared data.
            <br />
            3.2 The User shall provide a mechanism for data subjects to exercise
            their rights under applicable data protection laws.
          </p>
          <h3>4. Third-Party Sharing</h3>
          <p>
            4.1 The User shall not share the data with any third party without
            obtaining prior written consent from the provider.
          </p>
          <p>
            4.2 Any third party authorized to access the data shall be bound by
            terms and conditions similar to the agreed terms and conditions.
          </p>
          <h3>5. Breach Notification</h3>
          <p>
            5.1 The User shall promptly notify the Provider of any actual or
            suspected data breach involving the shared data within 72 hours if
            discovered in a timely manner or 48 hours if discovered late,
            according to the regulations by the Office of the Data Protection
            Commissioner.
          </p>
          <p>
            5.2 The notification shall include details of the breach, its
            impact, and any remedial actions taken or proposed.
          </p>
          <h3>6. Termination</h3>
          <p>
            6.1 Either party may terminate these Terms upon written notice if
            the other party breaches any material provision of these Terms.
          </p>
          <p>
            6.2 Upon termination, the User shall promptly return or destroy all
            copies of the shared data in its possession.
          </p>
          <h3>7. Miscellaneous</h3>
          <p>
            7.1 These Terms shall be considered to be an Appendix of The Data
            Sharing Agreement between the parties concerning the subject matter
            hereof and jointly supersede all prior agreements, whether oral or
            written.
          </p>
          <p>
            7.2 These Terms shall be governed by and construed in accordance
            with the laws of Kenya, The Data Protection Act 2019; The Data
            Protection (General) Regulations, 2021; The Data Protection
            (Registration of Data Controllers and Data Processors) Regulations,
            2021; The Data Protection (Complaints Handling and Enforcement
            Procedures) Regulations, 2021; The Ministry of Agriculture and
            Livestock Development Data Governance Framework and any other laws
            of Kenya that govern data and its usage.
          </p>
          <h3>8. Liability of Data Usage </h3>
          <p>
            Once approval has been granted under an agreed data sharing
            agreement, MoALD shall not be liable for any use or misuse of the
            shared data by stakeholders. MoALD hereby absolves itself from any
            responsibility, legal or otherwise, for the actions or consequences
            resulting from the utilisation of the shared data by stakeholders.
            Stakeholders are solely responsible for ensuring compliance with
            applicable laws, regulations, and ethical standards governing the
            use of the shared data. MoALD shall not be held accountable for any
            damages, losses, or liabilities arising directly or indirectly from
            the use or misuse of the shared data by stakeholders.
          </p>
          <p>
            <strong>Acceptance of Terms</strong> By accessing or using the data
            shared via API, download and viewing, the User agrees to be bound by
            these Terms.
          </p>
          <h3>Contact Information</h3>
          <p>
            Ministry of Agriculture and Livestock Development
            <br />
            P.O. Box 30028 - 00100
            <br />
            <a href="mailto:info@kilimo.go.ke">info@kilimo.go.ke</a>
            <br />
            <a href="tel:+254202718870">+254-20-2718870</a>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default TermsPage;
