import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Alert,
  ProgressBar,
} from "react-bootstrap";
import zxcvbn from "zxcvbn";
import PhoneInput from "react-phone-number-input";
import countiesData from "../data/counties_data.json";
import useApiRequest from "../hooks/useApiRequest";
import LoadingOverlay from "../components/layout/components/LoadingOverlay"; // Assuming you have this component
import { getCountyName } from "../utils/getCountiesFromCodes";
import MinistryLogo from "../components/logo/MinistryLogo";

const SignUp = () => {
  const navigate = useNavigate();
  const apiRequest = useApiRequest();
  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
    setValue,
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [newAccountSuccess, setNewAccountSuccess] = useState(false);
  const [apiError, setApiError] = useState("");
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordFeedback, setPasswordFeedback] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isNonKenyan, setIsNonKenyan] = useState(false);
  const [isCountyAccount, setIsCountyAccount] = useState(false);

  // Monitor password input and update strength/feedback
  useEffect(() => {
    const subscription = watch((value) => {
      if (value.password) {
        const result = zxcvbn(value.password);
        setPasswordStrength(result.score === 0 ? 0.5 : result.score);
        setPasswordFeedback(
          result.feedback.suggestions.join(" ") || "Password strength is good."
        );
      } else {
        setPasswordStrength(0);
        setPasswordFeedback("");
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === "password" || name === "confirmPassword") {
        if (value.password && value.confirmPassword) {
          if (value.password !== value.confirmPassword) {
            setError("confirmPassword", {
              type: "manual",
              message: "Passwords do not match",
            });
          } else {
            clearErrors("confirmPassword");
          }
        }
      }
      if (name === "tenantType" || name === "county") {
        if (value.tenantType === "county") {
          setIsCountyAccount(true);
        } else {
          setIsCountyAccount(false);
          setValue("organization", null);
        }
        if (value.county && value.tenantType === "county") {
          setValue(
            "organization",
            `County Government of ${getCountyName(value.county)}`
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setError, clearErrors, setValue]);

  // Handle phone number change
  useEffect(() => {
    register("phoneNumber", { required: "Phone number is required" });
  }, [register]);

  const handlePhoneNumberChange = (value) => {
    setPhoneNumber(value);
    setValue("phoneNumber", value);
  };

  const onCountryChange = (e) => {
    // Check if the selected country is not Kenya (KE)
    setIsNonKenyan(e !== "KE");
  };

  // Convert zxcvbn score to ProgressBar variant
  const strengthVariant = (score) => {
    switch (score) {
      case 0:
      case 0.5:
      case 1:
        return "danger";
      case 2:
        return "warning";
      case 3:
        return "info";
      default:
        return "success";
    }
  };

  // Sort counties for the select dropdown
  const sortedCounties = countiesData
    .slice()
    .sort((a, b) => a.name.localeCompare(b.name));

  // Handle form submission
  const onSubmit = async (data) => {
    clearErrors();
    if (passwordStrength < 2) {
      setError("password", {
        type: "manual",
        message: "Password is too weak",
      });
      return; // Prevent form submission
    }
    const requestBody = { ...data };

    setLoading(true);

    apiRequest({
      url: "/auth/register",
      method: "POST",
      body: requestBody,
      needsToken: false,
    })
      .then(async (response) => {
        if (response.status >= 200 && response.status < 300) {
          setNewAccountSuccess(true);
          setTimeout(() => navigate("/login"), 2000);
        } else {
          const errorBody = await response.json();
          const apiError =
            errorBody.errorMessage ??
            "Failed to Create Account. Please Try Again";
          setApiError(apiError);
          switch (apiError) {
            case "User exists with same username":
              setError("email", {
                type: "manual",
                message: "User exists with the same email address",
              });
              setApiError("User exists with the same email address");
              break;
            case "Phone number already in use. Please contact admin if you believe your number does not have an account with us":
              setError("phoneNumber", {
                type: "manual",
                message: "User exists with the same phone number",
              });
              break;

            default:
              break;
          }
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setError("Network error or unable to read the response.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <LoadingOverlay loading={loading} />}
      <Container className="mt-5">
        <Row className="justify-content-center">
          <Col md={6} lg={4}>
            <div className="w-100 d-flex justify-content-center mb-4">
              <MinistryLogo onClick={() => navigate("/")} />
            </div>
            <h6 className="text-center mb-4">ASDG Account Creation</h6>
            <Form onSubmit={handleSubmit(onSubmit)}>
              {/* First Name Input */}
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  {...register("firstname", {
                    required: "First name is required",
                  })}
                  placeholder="Enter your first name"
                  isInvalid={!!errors.firstname}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.firstname?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Last Name Input */}
              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  {...register("lastname", {
                    required: "Last name is required",
                  })}
                  placeholder="Enter your last name"
                  isInvalid={!!errors.lastname}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.lastname?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Email Input */}
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/,
                      message: "Invalid email address",
                    },
                  })}
                  placeholder="Enter your work email address"
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Phone Number Input with country flags */}
              <Form.Group className="mb-3">
                <Form.Label>Phone Number</Form.Label>
                <PhoneInput
                  international
                  defaultCountry="KE"
                  value={phoneNumber}
                  onChange={handlePhoneNumberChange}
                  addInternationalOption={false}
                  onCountryChange={onCountryChange}
                />
                {errors.phoneNumber && (
                  <div className="invalid-feedback d-block">
                    {errors.phoneNumber.message}
                  </div>
                )}
              </Form.Group>

              {isNonKenyan && (
                <Alert variant="danger">
                  We are only supported for people with Kenyan phone numbers for
                  now.
                </Alert>
              )}

              {/* Stakeholder Type Select */}
              <Form.Group controlId="accessType" className="mb-3">
                <Form.Label>Stakeholder Type</Form.Label>
                <Form.Select
                  {...register("tenantType", {
                    required: "Stakeholder Type is required",
                  })}
                  isInvalid={errors.tenantType}
                >
                  <option value="">Select Stakeholder Type</option>
                  <option value="national">National Government</option>
                  <option value="county">County Government</option>
                  <option value="ngo">NGOs and Civil Societies</option>
                  <option value="research">Research Institute</option>
                  <option value="private">Private Sector</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.tenantType && errors.tenantType.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* County Select */}
              <Form.Group className="mb-3">
                <Form.Label htmlFor="countySelect">Select County</Form.Label>
                <Form.Select
                  {...register("county", { required: "County is required" })}
                  isInvalid={!!errors.county}
                >
                  <option value="">Select a County</option>
                  {sortedCounties.map((county) => (
                    <option key={county.code} value={county.code}>
                      {county.name}
                    </option>
                  ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.county?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Organization Represented Input */}
              <Form.Group className="mb-3">
                <Form.Label>Organization Represented</Form.Label>
                <Form.Control
                  type="text"
                  {...register("organization", {
                    required: "Organization is required",
                  })}
                  placeholder="Organization Represented"
                  isInvalid={!!errors.organization}
                  disabled={isCountyAccount}
                />
                {isCountyAccount && (
                  <small>
                    <em>Auto-filled for county government representatives</em>
                  </small>
                )}
                <Form.Control.Feedback type="invalid">
                  {errors.organization?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Designation Input */}
              <Form.Group className="mb-3">
                <Form.Label>Your Designation</Form.Label>
                <Form.Control
                  type="text"
                  {...register("designation", {
                    required: "Designation is required",
                  })}
                  placeholder="Your Work Designation"
                  isInvalid={!!errors.designation}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.designation?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Password Input */}
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  {...register("password", {
                    required: "Password is required",
                    validate: (value) => {
                      const result = zxcvbn(value);
                      return result.score >= 2 || "Password is too weak";
                    },
                  })}
                  placeholder="Enter your password"
                  isInvalid={!!errors.password}
                />
                <ProgressBar
                  now={passwordStrength * 25}
                  variant={strengthVariant(passwordStrength)}
                  className={`mt-1 strength-${passwordStrength}`}
                />
                <Form.Text>{passwordFeedback}</Form.Text>
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {/* Confirm Password Input */}
              <Form.Group className="mb-3">
                <Form.Label>Confirm Password</Form.Label>
                <Form.Control
                  type="password"
                  {...register("confirmPassword", {
                    required: "Confirm password is required",
                    validate: (value) =>
                      value === watch("password") || "Passwords do not match",
                  })}
                  placeholder="Confirm your password"
                  isInvalid={!!errors.confirmPassword}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.confirmPassword?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {newAccountSuccess && (
                <Alert variant="success">Success! Account Created</Alert>
              )}
              {apiError && <Alert variant="danger">{apiError}</Alert>}
              <Button variant="primary" type="submit" className="w-100">
                Create Account
              </Button>
            </Form>
            <div className="mt-3 text-center">Already have an account?</div>

            <a href="/login" className="btn btn-success w-100 mt-2">
              Login
            </a>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SignUp;
