import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CustomNavLink from "./CustomNavLink";
import useStore from "../../../store/store";
import {
  faEnvelopeOpenText,
  faEnvelope,
  faUsers,
  faHourglassHalf,
  faCheck,
  faCheckDouble,
  faTimesCircle,
  faListAlt,
  faDatabase,
  faChartBar,
  faChartLine,
  faBook,
  faRepeat,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";

const NavAdmin = () => {
  const { isSuperAdmin } = useStore((state) => ({
    isSuperAdmin: state.isSuperAdmin,
  }));

  const navSections = [
    {
      header: "Stakeholder Approvals",
      items: [
        {
          to: "/a/email-verified-stakeholders-approval",
          icon: faEnvelopeOpenText, // Suggests email interaction, specifically verified
          label: "Verified Emails",
        },
        {
          to: "/a/non-email-verified-stakeholders-approval",
          icon: faEnvelope, // Indicates pending email interaction
          label: "Unverified Emails",
        },
        {
          to: "/a/all-stakeholders-approval",
          icon: faUsers, // Represents the collective group
          label: "All",
        },
      ],
    },
    {
      header: "Data Requests",
      items: [
        ...(!isSuperAdmin
          ? [
              {
                to: "/a/incoming-data-requests",
                icon: faHourglassHalf,
                label: "Incoming",
              },
              {
                to: "/a/maker-review-data-requests",
                icon: faRepeat,
                label: "Approver Review List",
              },
            ]
          : []),
        {
          to: "/a/verified-data-requests",
          icon: faCheck,
          label: "Verified",
        },
        ...(isSuperAdmin
          ? [
              {
                to: "/a/checker-review-data-requests",
                icon: faRepeat,
                label: "Approver Review List",
              },
            ]
          : []),
        {
          to: "/a/approved-data-requests",
          icon: faCheckDouble,
          label: "Approved",
        },
        {
          to: "/a/rejected-data-requests",
          icon: faTimesCircle,
          label: "Rejected",
        },
        {
          to: "/a/impasse-data-requests",
          icon: faExclamationCircle,
          label: "Impasse",
        },
        {
          to: "/a/all-data-requests",
          icon: faListAlt,
          label: "All",
        },
      ],
    },
    {
      header: "Resources",
      items: [
        { to: "/a/integrations", icon: faDatabase, label: "Data Sources" },
        { to: "/a/statistics", icon: faChartBar, label: "Statistics" },
        { to: "/a/reports", icon: faChartLine, label: "Requests Analysis" },
        ...(isSuperAdmin
          ? [
              {
                to: "/a/super-admin-guide",
                icon: faBook,
                label: "Super-Admin Guide",
              },
            ]
          : [{ to: "/a/admin-guide", icon: faBook, label: "Admin Guide" }]),
      ],
    },
  ];

  return (
    <ul
      className="navbar-nav bg-primary sidebar sidebar-dark accordion"
      id="accordionSidebar"
    >
      {navSections.map((section, index) => (
        <React.Fragment key={index}>
          <li className="nav-item">
            <a className="nav-link header" href="#">
              <span>{section.header}</span>
            </a>
          </li>
          {section.items.map((item, itemIndex) => (
            <CustomNavLink key={itemIndex} to={item.to}>
              <FontAwesomeIcon icon={item.icon} className="pe-2 ps-4" />
              <span>{item.label}</span>
            </CustomNavLink>
          ))}
        </React.Fragment>
      ))}
    </ul>
  );
};

export default NavAdmin;
