import React from "react";
import { Container, Row, Col, Card, Image, Stack } from "react-bootstrap";
import ProfileSettings from "../../components/Profile/ProfileSettings";
import useStore from "../../store/store";

const UserProfile = () => {
  const { user } = useStore((state) => ({
    user: state.user,
  }));

  // const apiRequest = useApiRequest();

  // const fetchData = async () => {
  //   try {
  //     const response = await apiRequest({
  //       url: "/user/profile", // The endpoint path relative to the base URL
  //       method: "GET",
  //     });

  //     if (response.ok) {
  //       const jsonData = await response.json();
  //       setJsonData(jsonData); // Assuming setJsonData is a state updater function
  //       // Additional handling of data, if needed
  //     } else {
  //       console.error("Error fetching data:", response.statusText);
  //     }
  //   } catch (error) {
  //     console.error("Error during data fetching:", error);
  //   }
  // };

  return (
    <Container fluid className="mt-4">
      <Row>
        <Col lg={12}>
          <Card className="mb-4">
            <Card.Header className="d-flex justify-content-between align-items-center">
              <h6>User Profile</h6>
            </Card.Header>
            <Card.Body>
              <Container>
                <Row>
                  {/* Profile Sidebar */}
                  <Col md={2}></Col>
                  <Col md={2}>
                    <Stack
                      gap={3}
                      className="align-items-center text-center py-5"
                    >
                      <Image
                        src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg"
                        alt="User Profile"
                        roundedCircle
                        width={150}
                        height={170}
                        className="mt-5"
                      />
                      {/* Additional information or elements can be added here as needed */}
                    </Stack>
                  </Col>

                  {/* Profile Settings */}
                  <Col md={6}>
                    <ProfileSettings userData={user} />
                  </Col>
                </Row>
              </Container>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default UserProfile;
